<p-confirmDialog></p-confirmDialog>
<span [formGroup]="form" class="flex">
  <p-dropdown
    #dropdown
    [options]="views"
    optionValue="id"
    optionLabel="name"
    placeholder="Aucune vue"
    appendTo="body"
    (onChange)="loadView($event.value)"
    emptyMessage="Aucune vue"
    [showClear]="true"
    panelStyleClass="views-list-component-dropdown"
  >
    <ng-template pTemplate="selectedItem">
      <span [class]="{ 'text-gray-400': selectedView?.readonly, 'text-blue-800': selectedView?.private }">
        <i class="pi pi-user" *ngIf="selectedView?.private"></i>
        {{ selectedView?.name }}</span
      >
    </ng-template>
    <ng-template let-item pTemplate="item">
      <span [class]="{ 'text-gray-400': item.readonly, 'text-blue-800': item.private }">
        <i class="pi pi-user" *ngIf="item.private"></i>
        {{ item.name }}</span
      >
    </ng-template>
  </p-dropdown>
  <p-button
    [disabled]="!!selectedView?.readonly"
    icon="pi pi-eye"
    class="ml-3"
    (click)="popupColumnsVisible = !selectedView?.readonly"
    label="Colonnes"
  ></p-button>
  <p-button icon="pi pi-plus" class="ml-3" (click)="popupCreatingVisible = true" label="Ajouter"></p-button>
  <p-button
    [disabled]="!!selectedView?.readonly"
    icon="pi pi-pencil"
    class="ml-3"
    *ngIf="selectedView?.id"
    (click)="popupRenamingVisible = !selectedView?.readonly; viewNameInput2 = selectedView?.name || ''"
    label="Renommer"
  ></p-button>
  <p-splitButton class="ml-3" label="Dupliquer" [model]="itemsDuplicate"></p-splitButton>
  <p-button
    [disabled]="!!selectedView?.readonly"
    icon="pi pi-trash"
    class="ml-3"
    *ngIf="selectedView?.id && isAdmin"
    (click)="!selectedView?.readonly && deleting()"
    label="Supprimer"
  ></p-button>
</span>

<p-dialog
  [(visible)]="popupCreatingVisible"
  [modal]="true"
  header="Créer une nouvelle vue"
  [style]="{ width: '300px' }"
>
  <div class="content text-center">
    <input pInputText [(ngModel)]="viewNameInput" required placeholder="Nom de la vue" (keyup.enter)="createView()" />
    <div class="mt-4">
      <p-selectButton
        [options]="publicPrivateOptions"
        [(ngModel)]="isPublic"
        optionLabel="label"
        optionValue="value"
      ></p-selectButton>
    </div>
  </div>
  <div class="ui-dialog-footer text-center mt-4">
    <p-button (click)="popupCreatingVisible = false" styleClass="p-button-outlined">Annuler</p-button>
    <p-button (click)="createView()">Valider</p-button>
  </div>
</p-dialog>

<p-dialog [(visible)]="popupRenamingVisible" [modal]="true" header="Renommer la vue" [style]="{ width: '550px' }">
  <div class="content text-center w-full">
    <textarea
      pInputTextarea
      [rows]="1"
      [autoResize]="true"
      [(ngModel)]="viewNameInput2"
      class="w-full"
      required
      placeholder="Nom de la vue"
    >
    </textarea>
  </div>
  <div class="ui-dialog-footer text-center mt-4">
    <p-button (click)="popupRenamingVisible = false" styleClass="p-button-outlined">Annuler</p-button>
    <p-button (click)="renaming()">Valider</p-button>
  </div>
</p-dialog>

<p-dialog [(visible)]="popupColumnsVisible" [modal]="true" header="Affichage des colonnes" [style]="{ width: '300px' }">
  <div class="content text-center">
    <ng-container *ngFor="let col of columns">
      <p-chip
        [label]="col.header"
        [ngClass]="{ 'mr-1': true, selected: columnIsDisplayed(col.field) }"
        (click)="toggleColumn(col.field)"
      ></p-chip>
    </ng-container>
  </div>
  <div class="ui-dialog-footer text-center mt-4">
    <p-button (click)="closePopupColumns()" styleClass="p-button-outlined">Fermer</p-button>
  </div>
</p-dialog>
