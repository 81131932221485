import { UntypedFormGroup } from "@angular/forms"

export function showFormErrors(fg: UntypedFormGroup): void {
  fg.markAsDirty()
  fg.updateValueAndValidity()
  ;(fg as any)._forEachChild((control: any) => {
    if (control instanceof UntypedFormGroup) {
      showFormErrors(control)
    } else {
      control.markAsDirty()
      control.updateValueAndValidity()
    }
  })
}

export function hideFormErrors(fg: UntypedFormGroup): void {
  fg.markAsPristine()
  fg.updateValueAndValidity()
  ;(fg as any)._forEachChild((control: any) => {
    if (control instanceof UntypedFormGroup) {
      hideFormErrors(control)
    } else {
      control.markAsPristine()
      control.updateValueAndValidity()
      control.hideErrors()
    }
  })
}

function formatError(value: string, code: string): { [key: string]: any } {
  return { [code]: { value } }
}
