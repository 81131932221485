import { BrowserModule } from "@angular/platform-browser"
import { isDevMode, LOCALE_ID, NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { RouteReuseStrategy, RouterModule } from "@angular/router"
import { HttpClientModule } from "@angular/common/http"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { RouteReusableStrategy, SharedModule } from "@shared"
import { AuthModule } from "@app/auth"
import { AppComponent } from "./app.component"
import { AppRoutingModule } from "./app-routing.module"
import { MainLayoutComponent } from "@app/layout/main-layout/main-layout.component"
import { SideBarComponent } from "@app/layout/main-layout/side-bar/side-bar.component"
import { registerLocaleData } from "@angular/common"
import localeFr from "@angular/common/locales/fr"
import { MessageService } from "primeng/api"
import { NotifService } from "@shared/service/notif.service"
import { ServiceWorkerModule } from "@angular/service-worker"

registerLocaleData(localeFr)

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    FormsModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    SharedModule,
    AuthModule,

    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent, MainLayoutComponent, SideBarComponent],
  providers: [
    // { provide: ErrorHandler, useClass: GlobalErrorHandlerService }, => catching wrong logging even if we already catch the error ???
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    { provide: LOCALE_ID, useValue: "fr-FR" },
    MessageService,
    NotifService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
