import { Component, HostListener, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router"
import { Title } from "@angular/platform-browser"
import { merge } from "rxjs"
import { filter, map, switchMap } from "rxjs/operators"

import { environment } from "@env/environment"
import { UntilDestroy, untilDestroyed } from "@shared"
import { ConfirmationService, PrimeNGConfig } from "primeng/api"
import { ProjectService } from "@app/pages/performance/project.service"
import { ExportIcsService } from "@app/pages/planning/export.ics.service"
import { PerformanceService } from "@app/pages/performance/peformance.service"
import { PersonService } from "@app/pages/person/person.service"
import { REFERENTIAL, ReferentialService } from "@app/pages/referential/referential.service"
import { UnitService } from "@app/pages/unit/unit.service"
import { ProgramService } from "@app/pages/program/program.service"
import { ListViewService } from "@shared/component/views-list/list-views.service"
import { EventService } from "@app/pages/performance/event.service"
import { NotifService } from "@shared/service/notif.service"
import { RemunerationQuarterlyService } from "@app/pages/remuneration/remuneration-quarterly.service"
import { RemunerationPositionService } from "@app/pages/remuneration/remuneration-position.service"
import { Utils } from "@shared/util/utils"
import { UserService } from "@app/pages/person/user.service"
import { SwUpdate } from "@angular/service-worker"

@UntilDestroy()
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.altKey && event.ctrlKey && event.code.toLowerCase() === "space") {
      this.exportFirestoreDATAtoJSON()
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private config: PrimeNGConfig,
    private remunerationService: RemunerationQuarterlyService,
    private remunerationPositionService: RemunerationPositionService,
    private performanceService: PerformanceService,
    private personService: PersonService,
    private programService: ProgramService,
    private projectService: ProjectService,
    private refService: ReferentialService,
    private unitService: UnitService,
    private viewService: ListViewService,
    private exportService: ExportIcsService,
    private eventService: EventService,
    private notifService: NotifService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private swUpdate: SwUpdate
  ) {
    if (this.swUpdate.isEnabled)
      this.swUpdate.checkForUpdate().then((newVersion) => {
        if (newVersion) {
          console.log("New version available!:")
          this.confirmationService.confirm({
            key: "UpdateMessage",
            message: "Souhaitez-vous mettre à jour l'application maintenant?",
            header: "Une mise à jour est disponible",
            icon: "pi pi-exclamation-triangle",
            acceptLabel: "Oui",
            rejectLabel: "Non",
            accept: () => window.location.reload(),
            reject: () => {},
          })
        }
      })
  }

  ngOnInit() {
    Utils.init()
    this.updateVersion()
    this.config.setTranslation({
      startsWith: "Commence par",
      contains: "Contient",
      notContains: "Ne contient pas",
      endsWith: "Termine par",
      equals: "Egal",
      notEquals: "Différent",
      noFilter: "Aucun filtre",
      dateIs: "Date égale à",
      dateIsNot: "Date différente de",
      dateBefore: "Date avant",
      dateAfter: "Date après",
      dateFormat: "dd/mm/yy",
      emptyFilterMessage: "Aucun résultat",
      dateAfterNow: "Dans le futur",
      dateBeforeNow: "Dans le passé",
    } as any)
    this.config.filterMatchModeOptions.date.push("dateAfterNow")
    this.config.filterMatchModeOptions.date.push("dateBeforeNow")

    // Setup logger
    if (environment.production) {
      // Logger.enableProductionMode()
    }

    localStorage["initialUrl"] = window.location.pathname
    console.debug("init", window.location.pathname)

    // Setup translations
    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd))

    // Change page title on navigation or language change, based on route data
    merge(onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute
          while (route.firstChild) {
            route = route.firstChild
          }
          return route
        }),
        filter((route) => route.outlet === "primary"),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event.title
        if (title) {
          this.titleService.setTitle("BO 3e-etage - " + title)
        }
      })
  }

  ngOnDestroy() {}

  updateVersion() {
    if (localStorage["version"] != "2.1") {
      localStorage.clear()
      localStorage["version"] = "2.1"
      console.log("VERSION UPDAPTE => 2.1")
    }
  }

  exportFirestoreDATAtoJSON() {
    this.viewService.setViewKey("performances")

    let promise = Promise.resolve()
    Object.values(REFERENTIAL)
      .map((key) => this.refService.getService(key))
      .concat([
        this.remunerationService,
        this.performanceService,
        this.personService,
        this.programService,
        this.projectService, // => events managed below
        this.unitService,
        this.userService,
        this.viewService, // !! only saving public performances views for now
      ] as any)
      .forEach((service) => {
        promise = promise.then(() =>
          service.exportAllToJson().then((content: any) => {
            const data = new File([JSON.stringify(content)], { type: "text/plain" } as any)
            this.exportService.downloadBlob(
              data,
              `ExportAll_${service.config.collectionKey}_${new Date().toISOString()}.json`
            )
          })
        )
      })

    const content = {}
    const contentRemPos = {}
    promise.then(() =>
      this.projectService.getAll().then((projects) => {
        let promises = Promise.resolve()
        projects.forEach((project) => {
          promises = promises.then(() => {
            this.eventService.setProjectCode(project.id)
            return this.eventService.exportAllToJson(project.id).then((events: any) => (content[project.id] = events))
          })
          promises = promises.then(() => {
            this.remunerationPositionService.setProjectCode(project.id)
            return this.remunerationPositionService
              .exportAllToJson()
              .then((versions: any) => (contentRemPos[project.id] = versions))
          })
        })
        promises.then(() => {
          const data = new File([JSON.stringify(content)], { type: "text/plain" } as any)
          this.exportService.downloadBlob(data, `ExportAll_projects_events_${new Date().toISOString()}.json`)

          const data2 = new File([JSON.stringify(contentRemPos)], { type: "text/plain" } as any)
          this.exportService.downloadBlob(data2, `ExportAll_remunerations_positions_${new Date().toISOString()}.json`)
          this.notifService.displaySuccess("Exports des données terminé!")
        })
      })
    )
  }
}
