import { Injectable } from "@angular/core"
import { FireStoreService } from "@shared/service/fire-store.service"
import { PersonDTO, PersonService } from "@app/pages/person/person.service"

@Injectable({
  providedIn: "root",
})
export class UserService extends FireStoreService<UserDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: "users",
      fullTextFields: [],
    })

    /**** Importing prod data ****/
    /*//Updating rules for users/ => allow write: if true;
    //Removing user collection then call:
    console.log("restoreData-user")
    this.restoreData([Content of ExportAll_users_XXXXXX.json] as any)*/
  }

  getAllFromCache(personService?: PersonService /*for avoiding Circular Dependencies*/): Promise<Array<UserDTO>> {
    return super.getAllFromCache().then((users) => {
      if (!personService) return users

      const promises: Array<Promise<any>> = []
      users.forEach((u) => {
        if (u.personId) promises.push(personService.get(u.personId.id).then((person) => (u.person = person)))
      })
      return Promise.all(promises).then(() => users)
    })
  }

  async get(id: string, fromCache = true, throwingError = true): Promise<UserDTO> {
    const offlineData = await this.getOfflineData(id)
    if (offlineData) return offlineData
    const data = await super.get(id, fromCache, throwingError)
    this.setOfflineData(id, data)
    return data
  }

  restoreData(array: []): any {
    const promises: Array<Promise<any>> = []
    array.forEach((json2: any) => {
      const { id, ...json } = json2
      Object.keys(json).forEach((key) => {
        const value = json[key]
        if (value.firestore === true) {
          const arr = value.path2.split("/")
          const doc = arr.pop()
          json[key] = this.firestore.collection(arr.join("/")).doc(doc).ref
        }
        promises.push(this.create(json, id))
      })
    })
    return promises
  }
}

export interface UserDTO {
  id: string
  planning?: boolean
  admin?: boolean
  email: string
  personId: { id: string }

  person?: PersonDTO // dynamically updated
}
