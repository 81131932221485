import { Injectable } from "@angular/core"
import { FireStoreService } from "@shared/service/fire-store.service"
import { DocumentReference } from "@angular/fire/compat/firestore"

@Injectable({
  providedIn: "root",
})
export class RemunerationPositionService extends FireStoreService<RemunerationPositionDTO> {
  static getEmptyDTO = (): RemunerationPositionDTO => ({
    date: new Date(),
    meta: { personsId: [] },
    data: {},
  })

  constructor() {
    super()
  }

  toDTO(dto: any, action: string): Promise<RemunerationPositionDTO> {
    const dto2 = dto as RemunerationPositionDTO
    if (dto2?.meta?.dates) {
      dto2.meta.dates = dto2?.meta?.dates.map((x: any) => {
        if (x.seconds) return new Date(x.seconds * 1000)
        return x
      })
    }
    return Promise.resolve(dto2)
  }

  setProjectCode(code: string): void {
    this.init({
      collectionKey: `remunerations/positions/${code}`,
      fullTextFields: [],
    })
  }

  createNewVersion(cmd: any): Promise<RemunerationPositionDTO> {
    cmd.date = new Date()
    return this.create(cmd)
  }

  getAllVersions(): Promise<Array<RemunerationPositionDTO>> {
    return this.getAll().then((res) => {
      res.sort((a: any, b: any) => (a.date > b.date ? -1 : 1))
      return res
    })
  }
}

export interface VersioningDTO {
  id?: string
  date: Date
  author?: string
  notes?: string
}

export interface RemunerationPositionDTO extends VersioningDTO {
  meta: {
    dates?: Array<Date>
    mapPersonDateEnable?: { [personId: string]: { [dateStr: string]: boolean } }
    personsId?: Array<string>
  }
  data: { [personId: string]: { [dateStr: string]: Array<DocumentReference<unknown /*ref-person-position*/>> } }
}
