import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { LoginComponent } from "@app/auth/components/login/login.component"

const routes: Routes = [{ path: "login", component: LoginComponent, data: { title: "Login" } }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
