import { Injectable } from "@angular/core"
import { FireStoreService } from "@shared/service/fire-store.service"
import { AngularFirestore, DocumentReference } from "@angular/fire/compat/firestore"
import { TableHeader } from "@shared/component/list/list.model"

export enum REFERENTIAL {
  project_code = "ref-project-code",
  perf_type = "ref-perf-type",
  perf_status = "ref-perf-status",
  perf_status_unexpected = "ref-perf-status-unexpected",
  currency = "ref-currency",
  season = "ref-season",
  video_storage = "ref-video-storage",
  planning_event_category = "ref-planning-evt-category",
  unity_type = "ref-unity-type",
  skills = "ref-skills",
  person_status = "ref-person-status",
  person_position = "ref-person-position",
  ratio_remuneration = "ref-ratio-remuneration",
  bonus_remuneration = "ref-bonus-remuneration",
  contact_label = "ref-contact-label",
}

@Injectable({
  providedIn: "root",
})
export class ReferentialService {
  fireStoreService: RefPerfStatusService
  skillDancerId = ""

  refs = {
    [REFERENTIAL.project_code]: "Code projet",
    [REFERENTIAL.perf_type]: "Type (représentation)",
    [REFERENTIAL.perf_status]: "Statut (représentation)",
    [REFERENTIAL.currency]: "Devise",
    [REFERENTIAL.season]: "Saison",
    [REFERENTIAL.video_storage]: "Stockage vidéo",
    [REFERENTIAL.perf_status_unexpected]: "Statut imprévus (représentation)",
    [REFERENTIAL.planning_event_category]: "Catégorie évènement (planning)",
    [REFERENTIAL.unity_type]: "Type de pièce",
    [REFERENTIAL.skills]: "Compétences",
    [REFERENTIAL.person_status]: "Statut (personne)",
    [REFERENTIAL.person_position]: "Poste/fonction personne",
    [REFERENTIAL.ratio_remuneration]: "Ratios pour rémuneration",
    [REFERENTIAL.bonus_remuneration]: "Bonus de rémuneration",
    [REFERENTIAL.contact_label]: "Label contact (général)",
  }

  mapGender = {
    MALE: "Homme",
    FEMALE: "Femme",
    OTHER: "Autre",
  }

  constructor(
    private firestore: AngularFirestore,
    private refPerfStatusService: RefPerfStatusService,
    private refPerfTypeService: RefPerfTypeService,
    private refProjectCodeService: RefProjectCodeService,
    private refCurrencyService: RefCurrencyService,
    private refSeasonService: RefSeasonService,
    private refVideoStorageService: RefVideoStorageService,
    private refPerfStatusUnexpectedService: RefPerfStatusUnexpectedService,
    private refEventCategoryService: RefEventCategoryService,
    private refUnityTypeService: RefUnityTypeService,
    private refSkillsService: RefSkillsService,
    private refPersonStatusService: RefPersonStatusService,
    private refPersonPositionService: RefPersonPositionService,
    private refRatioRemunerationService: RefRatioRemunerationService,
    private refBonusRemunerationService: RefBonusRemunerationService,
    private refContactLabelService: RefContactLabelService
  ) {
    this.fireStoreService = this.refPerfStatusService
  }

  getColumns(): TableHeader[] {
    return [{ field: "name", header: "Valeur", sort: true, sortField: "name" }].concat(
      this.fireStoreService.config.fields || []
    )
  }

  getReferentials(): Array<{ name: string; value: string }> {
    return Object.keys(this.refs)
      .filter((x) => x !== "ref-person-role")
      .map((key) => ({ name: this.refs[key], value: key }))
  }

  getReferentialLabel(key: REFERENTIAL): string {
    return this.refs[key]
  }

  getService(key: REFERENTIAL): RefPerfStatusService {
    switch (key) {
      case REFERENTIAL.project_code:
        return this.refProjectCodeService
      case REFERENTIAL.perf_type:
        return this.refPerfTypeService
      case REFERENTIAL.perf_status:
        return this.refPerfStatusService
      case REFERENTIAL.currency:
        return this.refCurrencyService
      case REFERENTIAL.season:
        return this.refSeasonService
      case REFERENTIAL.video_storage:
        return this.refVideoStorageService
      case REFERENTIAL.perf_status_unexpected:
        return this.refPerfStatusUnexpectedService
      case REFERENTIAL.planning_event_category:
        return this.refEventCategoryService
      case REFERENTIAL.unity_type:
        return this.refUnityTypeService
      case REFERENTIAL.skills:
        return this.refSkillsService
      case REFERENTIAL.person_status:
        return this.refPersonStatusService
      case REFERENTIAL.person_position:
        return this.refPersonPositionService
      case REFERENTIAL.ratio_remuneration:
        return this.refRatioRemunerationService
      case REFERENTIAL.bonus_remuneration:
        return this.refBonusRemunerationService
      case REFERENTIAL.contact_label:
        return this.refContactLabelService
      default:
        throw "REFERENTIAL UNKNOWN - " + key
    }
  }

  changeService(key: REFERENTIAL): void {
    this.fireStoreService = this.getService(key)
  }

  getAllFromCache(key: REFERENTIAL): Promise<Array<ReferentialDTO>> {
    return this.getService(key).getAllFromCache()
  }

  getGenderRef(): Array<{ label: string; value: string }> {
    return Object.keys(this.mapGender).map((key) => ({ label: this.mapGender[key], value: key }))
  }

  getDancerSkillId(skills: Array<ReferentialDTO>): string {
    return skills.find((x: ReferentialDTO) => x.name?.includes("Interprète" /* FIXME*/))?.id || ""
  }

  getDancerSkillId2(): Promise<string> {
    if (this.skillDancerId) {
      return Promise.resolve(this.skillDancerId)
    }

    return this.getAllFromCache(REFERENTIAL.skills).then((skills) => {
      const id = skills.find((x: ReferentialDTO) => x.name?.includes("Interprète" /* FIXME*/))?.id || ""
      this.skillDancerId = id
      return id
    })
  }

  getRefFromId(key: REFERENTIAL, id: string): DocumentReference<unknown> {
    const collectionKey = this.getService(key).config.collectionKey
    return this.firestore.doc(`${collectionKey}/${id}`).ref
  }
}

export interface ReferentialDTO {
  id: string
  name?: string
  color?: string
  distribution?: boolean
  value?: string
}

@Injectable({
  providedIn: "root",
})
class RefProjectCodeService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.project_code,
      fullTextFields: ["name"],
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefPerfTypeService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.perf_type,
      fullTextFields: ["name"],
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefPerfStatusService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.perf_status,
      fullTextFields: ["name"],
      fields: [
        { field: "visible", header: "Visibilité externe", sort: true, sortField: "visible", type: "checkbox" },
        { field: "color", header: "Couleur", sort: true, sortField: "color", type: "color" },
        {
          field: "contractExcluded",
          header: "Exclure des contrats",
          sort: true,
          sortField: "contractExcluded",
          type: "checkbox",
        },
        {
          field: "seniorityExcluded",
          header: "Exclure de l'ancienneté",
          sort: true,
          sortField: "seniorityExcluded",
          type: "checkbox",
        },
      ],
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefCurrencyService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.currency,
      fullTextFields: ["name"],
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefSeasonService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.season,
      fullTextFields: ["name"],
      sorting: {
        field: "name",
        order: "desc",
      },
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefPerfStatusUnexpectedService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.perf_status_unexpected,
      fullTextFields: ["name"],
      sorting: {
        field: "name",
        order: "desc",
      },
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefVideoStorageService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.video_storage,
      fullTextFields: ["name"],
      sorting: {
        field: "name",
        order: "desc",
      },
    })
  }
}

export enum EVENT_CATEGORY {
  EXCLUDED_TOURNEE = "exclude",
  EXCLUDED_PAIE = "exclude_rem",
}

@Injectable({
  providedIn: "root",
})
class RefEventCategoryService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.planning_event_category,
      fullTextFields: ["name"],
      sorting: {
        field: "name",
        order: "desc",
      },
      fields: [
        { field: "color", header: "Couleur", sort: true, sortField: "color", type: "color" },
        {
          field: EVENT_CATEGORY.EXCLUDED_TOURNEE,
          header: "Exclure des tournées",
          sort: true,
          sortField: "exclude",
          type: "checkbox",
        },
        {
          field: EVENT_CATEGORY.EXCLUDED_PAIE,
          header: "Exclure de la paie",
          sort: true,
          sortField: "exclude",
          type: "checkbox",
        },
      ],
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefUnityTypeService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.unity_type,
      fullTextFields: ["name"],
      sorting: {
        field: "name",
        order: "desc",
      },
      fields: [
        { field: "color", header: "Couleur", sort: true, sortField: "color", type: "color" },
        { field: "visible", header: "Visibilité externe", sort: true, sortField: "visible", type: "checkbox" },
      ],
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefSkillsService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.skills,
      fullTextFields: ["name"],
      sorting: {
        field: "name",
        order: "asc",
      },
      fields: [
        { field: "distribution", header: "Distribution", sort: true, sortField: "distribution", type: "checkbox" },
      ],
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefPersonStatusService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.person_status,
      fullTextFields: ["name"],
      fields: [{ field: "color", header: "Couleur", sort: true, sortField: "color", type: "color" }],
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefPersonPositionService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.person_position,
      fullTextFields: ["name"],
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefRatioRemunerationService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.ratio_remuneration,
      fullTextFields: ["name"],
      fields: [{ field: "value", header: "Ratio (%)", sort: true, sortField: "value", type: "number" }],
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefBonusRemunerationService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.bonus_remuneration,
      fullTextFields: ["name"],
      sorting: {
        field: "name",
        order: "desc",
      },
    })
  }
}

@Injectable({
  providedIn: "root",
})
class RefContactLabelService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.contact_label,
      fullTextFields: ["name"],
      sorting: {
        field: "name",
        order: "desc",
      },
    })
  }
}
