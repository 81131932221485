@if (contact) { @for (conf of configuration; let index = $index; track conf.fieldName) { @if (conf.multi) {

<p-fieldset [legend]="conf.label" class="relative" [toggleable]="true">
  @for (fieldName of getMultiFields(conf.fieldName); let index = $index; track fieldName) {
  <app-contact-field
    [multiFieldName]="conf.label"
    [row]="contact[fieldName]"
    [type]="conf.type"
    [uuid]="uuid"
  ></app-contact-field>
  }
  <div class="absolute" style="top: 4px; right: 5px">
    <p-button
      icon="pi pi-plus"
      [rounded]="true"
      severity="secondary"
      (click)="addField(conf.fieldName, conf.label)"
    ></p-button>
  </div>
</p-fieldset>
} @else {
<app-contact-field
  [label]="conf.label"
  [row]="contact[conf.fieldName]"
  [type]="conf.type"
  [uuid]="uuid"
  [fetchData]="conf.fetchData"
></app-contact-field>
} } }
