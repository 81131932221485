<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

<div [ngClass]="{ details: sideBarShown, 'mobile-all': isMobile }">
  <app-side-bar (opening)="sideBarOpened($event)"></app-side-bar>
  <main>
    <div class="content">
      <router-outlet (activate)="onActivate()"></router-outlet>
    </div>
  </main>
</div>
