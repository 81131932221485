import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { showFormErrors } from "@shared/util/form.util"

@Component({
  selector: "app-form-submit",
  templateUrl: "./form-submit.component.html",
  styleUrls: ["./form-submit.component.scss"],
})
export class FormSubmitComponent implements OnInit {
  @Input() label = ""
  @Input() form?: UntypedFormGroup
  @Output() submitted = new EventEmitter()

  constructor() {}

  ngOnInit(): void {}

  validate(): void {
    if (!this.form || this.form.valid) {
      this.submitted.emit()
    } else {
      showFormErrors(this.form)
    }
  }
}
