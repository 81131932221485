import { Injectable } from "@angular/core"
import { FireStoreService } from "@shared/service/fire-store.service"
import { CalendarViewDTO } from "@app/pages/planning/calendar-views.service"

@Injectable({
  providedIn: "root",
})
export class ViewService extends FireStoreService<CalendarViewDTO> {
  constructor() {
    super()
  }
  setProjectCode(code: string): void {
    this.init({
      collectionKey: `projects/${code}/views`,
      fullTextFields: [],
    })
  }

  get(id: string): Promise<CalendarViewDTO> {
    return super.get(id, true)
  }
}
