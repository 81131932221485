<div
  class="field grid"
  *ngIf="row"
  [ngClass]="{ 'opacity-50 pointer-events-none': !row?._rights?.write?.includes(uuid) }"
>
  <label class="col-12 lg:col-4 md:col-4"
    >{{ label }}
    @if (multiFieldName) {
    <span class="p-float-label">
      <p-dropdown
        [options]="referentialLabels"
        [(ngModel)]="row.label"
        placeholder="Selectionner un libellé"
        [editable]="true"
        optionValue="id"
        optionLabel="name"
      ></p-dropdown>
    </span>
    }
  </label>
  <div class="col">
    <div class="flex flex-column">
      <div [style.visibility]="row?._rights?.read?.includes(uuid) ? 'visible' : 'hidden'">
        @if(showVersions) {
        <i (click)="showVersions = !showVersions" class="pi pi-minus cursor-pointer"></i>
        }@else {
        <svg
          (click)="showVersions = !showVersions"
          style="width: 14px"
          class="cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            fill="#333333"
            d="M80 104a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm80-24c0 32.8-19.7 61-48 73.3v87.8c18.8-10.9 40.7-17.1 64-17.1h96c35.3 0 64-28.7 64-64v-6.7C307.7 141 288 112.8 288 80c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V160c0 70.7-57.3 128-128 128H176c-35.3 0-64 28.7-64 64v6.7c28.3 12.3 48 40.5 48 73.3c0 44.2-35.8 80-80 80s-80-35.8-80-80c0-32.8 19.7-61 48-73.3V352 153.3C19.7 141 0 112.8 0 80C0 35.8 35.8 0 80 0s80 35.8 80 80zm232 0a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zM80 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"
          />
        </svg>
        }

        <span class="p-input-icon-right" style="width: 90%">
          <i
            class="pi pi-exclamation-triangle"
            style="color: red !important; z-index: 1"
            *ngIf="!noChanges() && row.value"
          ></i>
          <i class="pi pi-check" style="color: green !important; z-index: 1" *ngIf="noChanges()"></i>
          <input pInputText *ngIf="type === 'string'" [(ngModel)]="row.value" style="width: 100%" />
          <app-multiselect-firestore
            *ngIf="type === 'dropdown'"
            [referentialKey]="REFERENTIAL.skills"
            [(ngModel)]="row.value"
            [fetchData]="fetchData"
            [maxSelectedLabels]="2"
            selectedItemsLabel="{0} compétences"
          ></app-multiselect-firestore>
          <p-calendar [(ngModel)]="row.value" *ngIf="type === 'date'" appendTo="body"></p-calendar>
          <p-dropdown
            [options]="genderOptions"
            *ngIf="type === 'gender'"
            placeholder=""
            [(ngModel)]="row.value"
          ></p-dropdown>
        </span>
      </div>
      <!-- Versioning-->
      <p-dropdown
        [options]="this.row.versioning"
        placeholder="Aucune version"
        appendTo="body"
        emptyMessage="Aucune version"
        [(ngModel)]="selected"
        class="mt-1 versioning"
        *ngIf="showVersions"
      >
        <ng-template let-item pTemplate="item">
          <div [title]="" class="flex justify-content-between">
            <ng-container *ngIf="type === 'gender'">{{ refService.mapGender[item.value] || "-" }}</ng-container>
            <ng-container *ngIf="type === 'string'">{{ item.value || "-" }}</ng-container>
            <ng-container *ngIf="type === 'date'">{{
              item.value ? ($any(item.value) | date : "DD/MM/YYYY") : "-"
            }}</ng-container>
            <ng-container *ngIf="type == 'dropdown'">{{ formatSkills(item.value) }}</ng-container>
            <span class="text-gray-500 text-sm ml-2">
              {{ item.label ? "[" + item.label + "] - " : "" }} {{ item.date | date : "DD/MM/YYYY à HH:mm" }} -
              {{ item.who }}</span
            >
          </div>
        </ng-template>
        <ng-template let-item pTemplate="selectedItem">
          <div class="flex justify-content-between">
            <span class="text-gray-300 text-sm">
              {{ item.date | date : "DD/MM/YYYY à HH:mm" }} - {{ item.label ? "[" + item.label + "] - " : "" }}
              {{ item.who }}</span
            >
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
</div>
