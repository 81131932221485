import { Component, Input } from "@angular/core"
import { CrmContactDTO } from "@app/pages/person/person.service"

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrl: "./contact-form.component.scss",
})
export class ContactFormComponent {
  @Input() uuid = ""
  @Input() configuration = [] as any
  @Input() contact?: CrmContactDTO

  constructor() {}

  async addField(name: string, label: string) {
    if (!this.contact) return
    let index = 0
    while (this.contact[name + "_" + index]) index++
    const fieldName = name + "_" + index
    this.contact[fieldName] = {
      label: `${label} ${index}`,
      value: "",
      category: "main",
      versioning: [],
    }
    // @ts-ignore
    this.contact[fieldName]._rights = {
      write: [this.uuid],
      read: [this.uuid],
      own: [this.uuid],
    }
    this.contact.multiFieldNames = this.contact.multiFieldNames || []
    this.contact.multiFieldNames.push(fieldName)
  }

  getMultiFields(fieldName: string): Array<string> {
    return this.contact?.multiFieldNames?.filter((m) => m.includes(fieldName)) || []
  }
}
