import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"
import { GenericDropDownView } from "@shared/component/dropdown-ref-firestore/dropdown-ref-firestore.component"
import { AngularFirestore, DocumentReference } from "@angular/fire/compat/firestore"

@Component({
  selector: "app-multiselect-firestore",
  templateUrl: "./multiselect-ref-firestore.html",
  styleUrls: ["./multiselect-ref-firestore.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiselectRefFirestoreComponent,
    },
  ],
})
export class MultiselectRefFirestoreComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() referentialKey = ""
  @Input() required!: boolean
  @Input() maxSelectedLabels = 3
  @Input() filter = true
  @Input() initialFilter = ""
  @Input() disabled = false
  @Input() selectedItemsLabel = "{0} élements"

  selectedOptions?: GenericDropDownView[]
  filterValue = ""

  options: GenericDropDownView[] = []

  idsWritten: string[] | null = null

  // form related data
  formTouched = false
  formDisabled = false
  onChangeForm = (refs?: DocumentReference<unknown>[]) => {}
  onTouchedForm = () => {}
  @Input() fetchData: () => Promise<GenericDropDownView[]> = () => Promise.resolve([])
  @Output() change = new EventEmitter<GenericDropDownView[]>()

  constructor(private firestore: AngularFirestore) {}

  ngOnInit(): void {
    this.filterValue = this.initialFilter
    this.fetchData().then((options) => {
      this.options = options
      this.selectFromWritten()
    })
    if (!this.referentialKey) {
      throw new Error("referentialKey should no be null (DropdownRefFirestoreComponent)")
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("changes", changes)
    const disableChange = changes.disable
    if (disableChange) {
      this.formDisabled = disableChange.currentValue
    }
  }

  onChangeEvent($event: any): void {
    // console.log("onChangeEvent", $event)
    // console.log("filterValue", this.filterValue)
    if (!this.formDisabled) {
      this.markAsTouched()
      // const newIds = this.selectedOptions?.map((v) => v.id)

      const refs = this.selectedOptions?.map((op) => this.firestore.doc(this.referentialKey + "/" + op.id).ref)
      // const ref = this.firestore.doc(this.referentialKey + "/" + this.selectedOption?.id).ref
      this.onChangeForm(refs)
      // this.idsChange.emit(newIds)
      // business wants that filter is empty after selection -> might need to skip on un-select value -> will see
      if (this.filterValue !== "") {
        this.filterValue = ""
      } else {
        this.filterValue = this.filterValue + " "
        setTimeout(() => {
          this.filterValue = ""
        }, 20)
      }
      this.change.emit(this.selectedOptions)
    }
  }

  registerOnChange(fn: any): void {
    // console.log("registerOnChange", fn)
    this.onChangeForm = fn
  }

  registerOnTouched(fn: any): void {
    // console.log("registerOnTouched", fn)
    this.onTouchedForm = fn
  }

  setDisabledState(isDisabled: boolean): void {
    // console.log("setDisabledState", isDisabled)
    this.formDisabled = isDisabled
  }

  writeValue(ids: any[]): void {
    const promises: Promise<string>[] = []
    ids?.forEach((id) => {
      if (id && id.id2) {
        promises.push(Promise.resolve(id.id2))
      } else if (id && id.get) {
        promises.push(id.get().then((val: any) => val.id))
      }
    })
    Promise.all(promises).then((res) => {
      this.idsWritten = res
      this.selectFromWritten()
    })
  }

  private markAsTouched(): void {
    if (!this.formTouched) {
      this.onTouchedForm()
      this.formTouched = true
    }
  }

  private selectFromWritten(): void {
    if (this.idsWritten) {
      const found = this.options.filter((v) => this.idsWritten?.find((v2) => v2 === v.id) !== undefined)
      if (found) {
        this.selectedOptions = found
      }
    } else {
      this.selectedOptions = undefined
    }
  }
}
