import * as dayJS from "dayjs"
import * as utc from "dayjs/plugin/utc"
import * as timezone from "dayjs/plugin/timezone"
import "dayjs/locale/fr"

dayJS.extend(utc)
dayJS.extend(timezone)
dayJS.locale("fr")
dayJS.tz.setDefault("Europe/Paris")

// ;(window as any).dayJS = dayJS // for debugging

export class DateUtils {
  /*** Formatting ***/

  public static newDateFromYYYYMMDD(date?: string): Date {
    return dayJS.tz(date || "").toDate()
  }

  public static formatForApiCall(date: Date): string {
    return dayJS.tz(new Date(date)).format("YYYY-MM-DD")
  }

  public static formatFullDate(date: Date): string {
    return dayJS.tz(new Date(date)).format("dddd DD MMMM YYYY")
  }

  public static formatHH_MM(date: Date | string, separator = ":"): string {
    return new Date(date).toLocaleTimeString("fr-FR").slice(0, 5)
  }

  public static formatHH_MM_SS(date: Date | string, separator = ":"): string {
    return dayJS.tz(new Date(date)).format(`HH${separator}mm${separator}ss`)
  }

  public static formatDD_MM_YYYY(date: Date | string, sep = "/"): string {
    return dayJS.tz(new Date(date)).format(`DD${sep}MM${sep}YYYY`)
  }

  public static formatDD_MM_YYYY_HH_MM_SS(date: Date | string, separator = " à "): string {
    return dayJS.tz(new Date(date)).format(`DD/MM/YYYY${separator}HH:mm:ss`)
  }

  public static formatdddd_DD_MM_YYYY_HH_MM(date: Date | string, separator = " à "): string {
    return dayJS.tz(new Date(date)).format(`dddd DD/MM/YYYY${separator}HH:mm`)
  }

  public static getFullDateFromHours(date: Date, hour: /*hh:mm*/ string): Date {
    const [h, m] = hour.split(":")
    return new Date(new Date(date).setHours(+h, +m, 0, 0))
  }

  /*** Operations ***/
  public static operationSubstractOneDay(date: Date): Date {
    return new Date(date.getTime() - 24 * 60 * 60 * 1000)
  }

  public static operationAddOneDay(date: Date): Date {
    return new Date(date.getTime() + 24 * 60 * 60 * 1000)
  }

  public static operationAddSeconds(date: Date, seconds: number): Date {
    return new Date(date.getTime() + seconds * 1000)
  }

  /*** Default date ***/
  public static getDefaultDate(): Date {
    return new Date(0)
  }

  public static isDefaultDate(date: Date): boolean {
    return new Date(0).toLocaleDateString() === date.toLocaleDateString()
  }

  /*** Duration ***/
  public static formatDurationDD_HH_MM(timeSec: number): string {
    const days = timeSec / (3600 * 24)
    if (days > 1) {
      return Math.round(days) + " jour" + (Math.round(days) > 1 ? "s" : "")
    } else {
      return DateUtils.formatDuration(timeSec)
    }
  }

  public static formatSmallHour(hour = "" /* hh:mm */): string {
    const [h, m] = hour.split(":")
    const res = [`${+h}`]
    if (+m !== 0) res.push(m)
    return res.join(":")
  }

  public static formatDuration(seconds: number, minutesStr = "'"): string {
    let [hour, minute] = new Date(new Date(0, 0).setSeconds(seconds))
      .toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
      })
      .split(":") as any
    hour = +hour
    if (hour === 0) {
      minute = +minute
    }
    return (+hour > 0 ? +hour + "h" : "") + (+minute > 0 ? minute + (+hour > 0 ? "" : minutesStr) : "")
  }

  public static getSecondsFromDuration(/*hh:mm*/ duration: string): number {
    if (/^[0-9]{1,2}:[0-9]{1,2}$/.test(duration)) {
      const [hours, minutes] = duration.split(":")
      return +hours * 60 * 60 + +minutes * 60
    } else {
      throw new Error("duration badly formatted hh:mm :" + duration)
    }
  }

  public static getKeyFromDate(date?: Date): string {
    return date ? date.toLocaleDateString("en-US") : "??"
  }
}
