import { Injectable } from "@angular/core"
import { FireStoreService } from "@shared/service/fire-store.service"

@Injectable({
  providedIn: "root",
})
export class RemunerationQuarterlyService extends FireStoreService<RemunerationQuarterlyDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: "accounts",
      fullTextFields: [],
    })
  }
}

export interface RemunerationQuarterlyDTO {
  id: string
  DA_DD: {
    DA_fixed: { [personId: string]: number }
    DD_fixed: { [personId: string]: number }
  }
}
