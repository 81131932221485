import { Injectable } from "@angular/core"
import { FireStoreService } from "@shared/service/fire-store.service"

@Injectable({
  providedIn: "root",
})
export class ListViewService extends FireStoreService<ListViewDTO> {
  key = ""

  constructor() {
    super()
  }

  create(cmd: any, personId?: string): Promise<ListViewDTO> {
    return this.executeIn<ListViewDTO>(personId, () =>
      super.create(cmd).then((v) => {
        v.private = !!personId
        return v
      })
    )
  }

  update(cmd: any, personId?: string): Promise<ListViewDTO> {
    return this.executeIn<ListViewDTO>(personId, () => super.update(cmd))
  }

  delete(cmd: any, soft = false): any {
    throw new Error("Call delete2 instead")
  }

  delete2(cmd: any, personId?: string): Promise<any> {
    return this.executeIn(personId, () => super.delete(cmd, true))
  }

  setViewKey(key: string): void {
    this.key = key
    this.init({
      collectionKey: `views/${key}/data`, //public
      fullTextFields: [],
    })
  }

  async executeIn<T>(
    personId: string | undefined /*if personId not null => private, otherwise => public*/,
    callback: () => Promise<T>
  ): Promise<T> {
    if (personId) {
      this.config.collectionKey = `views/${this.key}/${personId}`
    }
    const res = await callback()
    this.config.collectionKey = `views/${this.key}/data`
    return res
  }

  async getAllFromCache(personId?: string): Promise<Array<ListViewDTO>> {
    let views = await super.getAll() // public
    views.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    if (personId) {
      const privateViews = await this.executeIn<ListViewDTO[]>(personId, () => super.getAll())
      privateViews.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
      privateViews.map((p) => (p.private = true))
      views = privateViews.concat(views)
    }
    return views
  }
}

export interface ListViewDTO {
  id: string
  name: string
  stringified: string
  readonly?: boolean
  private?: boolean
}
