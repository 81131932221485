import { Injectable } from "@angular/core"
import { DocumentSnapshot } from "@angular/fire/compat/firestore"
import { FireStoreService } from "@shared/service/fire-store.service"
import { FilterMetadata } from "primeng/api"
import { REFERENTIAL, ReferentialService } from "@app/pages/referential/referential.service"
import { UserService } from "@app/pages/person/user.service"
import { ListSortParameters } from "@shared/component/list/list.model"
import { deleteField } from "@angular/fire/firestore"

@Injectable({
  providedIn: "root",
})
export class PersonService extends FireStoreService<PersonDTO> {
  myUuid = ""

  constructor(private refService: ReferentialService, private userService: UserService) {
    super()
    this.init({
      collectionKey: "persons",
      fullTextFields: ["firstName", "lastName", "nickName"],
      sorting: {
        field: "lastName",
      },
    })
    // Pour que cela fonctionne, il faut donner tous les droits de toutes les personnes au admin (rules.rules)...
    // this.geAllNoRestrictionsDebug()
  }

  // geAllNoRestrictionsDebug(): any {
  //   const collectionKey = this.config.collectionKey
  //   return this.firestore
  //     .collection(collectionKey)
  //     .get()
  //     .toPromise()
  //     .then((res) => {
  //       const items: Array<Promise<PersonDTO>> = []
  //       res?.forEach((r) => {
  //         if (!(r.data() as any).deleted) {
  //           items.push(
  //             this._toDTO(r, "").then((res) => {
  //               return this.getFields(res.id || "", this.myUuid, true).then((fields) => {
  //                 Object.assign(res, this._fieldsToContactDTO(fields))
  //                 return this.toDTO(res, "")
  //               })
  //             })
  //           )
  //         }
  //       })
  //       console.log(`!QUOTA! firestore reading - geAllNoRestrictionsDebug ${collectionKey}`, items.length)
  //       return Promise.all(items)
  //     })
  //     .then((persons) => {
  //       console.log("All persons", persons)
  //       console.log("Persons not visibles")
  //       console.table(persons.filter((x: any) => !x._rights?.read?.includes("YW2Mkb1bbhYol6z1JJ5aqdv4Ojr1")))
  //     })
  //     .catch((err) => this.handleError(err, []))
  // }

  setUuid(uuid: string): void {
    this.myUuid = uuid
  }

  toDTO(person: any, action: string): Promise<any> {
    return Promise.all([this.refService.getAllFromCache(REFERENTIAL.skills)]).then((refs) => {
      const [refSkills] = refs
      person.skills = Array.isArray(person?.skills /*could be 'xx' if no rights*/) ? person?.skills : []
      const skills = person.skills.map((x: any) => refSkills.find((y) => y.id === x.id)) || []
      person.fullName = `${person?.firstName || ""} ${person?.lastName || ""}`
      person.fullName2 = `${person?.lastName || ""} ${person?.firstName || ""}`
      person.skills_label = skills.map((x: any) => x?.name).join(", ")
      return person
    })
  }

  async getPersonFromFirebaseUid(uid: string): Promise<PersonDTO> {
    const offlineData = await this.getOfflineData(uid)
    if (offlineData) return offlineData
    return this.userService
      .get(uid, true)
      .then((user) => {
        if (user?.personId?.id) {
          return this.getWithFields(user?.personId?.id || "", true, true).then((person) => {
            person.admin = user?.admin
            person.rolePlanning = user?.planning
            return person
          })
        }
        return {
          admin: user?.admin,
          rolePlanning: user?.planning,
        } as PersonDTO
      })
      .then((data) => {
        this.setOfflineData(uid, data)
        return data
      })
  }

  getAll(limit?: number, checkAccount = false): Promise<Array<PersonDTO>> {
    return this.checkUserAccount(super.getAll(limit), checkAccount)
  }

  getMore(limit?: number, checkAccount = false): Promise<Array<PersonDTO>> {
    return this.checkUserAccount(super.getMore(limit), checkAccount)
  }

  get(id: string, fromCache = false): Promise<PersonDTO> {
    return this.getWithFields(id, fromCache, true)
  }

  getWithFields(id: string, fromCache = false, onlyNames = false): Promise<PersonDTO> {
    return super
      .get(id, fromCache, false /*if rights error, returning empty object instead of error*/)
      .then((dto) => {
        if (dto) {
          if (fromCache && dto.firstName !== undefined && onlyNames) return dto

          return (onlyNames ? this.getOnlyNamesFields(dto.id || "") : this.getFields(dto.id || "", this.myUuid)).then(
            (fields) => {
              Object.assign(dto, this._fieldsToContactDTO(fields))
              return this.toDTO(dto, "")
            }
          )
        }
        return dto
      })
      .then((obj) => {
        this._updateCache(this.config.collectionKey, obj)
        return obj
      })
  }

  _getAllPaginated(
    collectionKey: string,
    limit?: number,
    startAfter?: DocumentSnapshot<any>
  ): Promise<Array<PersonDTO>> {
    const action = limit ? FireStoreService.ACTION.ALL_PAGINATED : FireStoreService.ACTION.ALL
    return this.firestore
      .collection(collectionKey, (ref) => {
        let ref2 = limit ? ref.limit(limit) : ref
        // ref2 = this.config.sorting ? ref2.orderBy(this.config.sorting.field, this.config.sorting.order) : ref2
        ref2 = ref2.where("_rights.read", "array-contains", this.myUuid)
        return startAfter ? ref2.startAfter(startAfter) : ref2
      })
      .get()
      .toPromise()
      .then((res) => {
        const items: Array<Promise<PersonDTO>> = []
        res?.forEach((r) => {
          if (!(r.data() as any).deleted) {
            items.push(
              this._toDTO(r, action).then((res) => {
                return this.getOnlyNamesFields(res.id || "").then((fields) => {
                  Object.assign(res, this._fieldsToContactDTO(fields))
                  return this.toDTO(res, action)
                })
              })
            )
            this.startAfterPagination = r as any
          }
        })
        console.log(`!QUOTA! firestore reading - _getAllPaginated ${collectionKey}`, items.length)
        return Promise.all(items)
      })
      .catch((err) => this.handleError(err, []))
  }

  filter(
    search?: string,
    sort?: ListSortParameters,
    filters?: FilterMetadata,
    checkAccount = false
  ): Promise<Array<PersonDTO>> {
    return this.checkUserAccount(super.filter(search, sort, filters), checkAccount)
  }

  checkUserAccount(promise: Promise<Array<PersonDTO>>, checkAccount: boolean): Promise<Array<PersonDTO>> {
    if (!checkAccount) {
      return promise
    }

    return this.userService.getAllFromCache().then((users) => {
      return promise.then((results) =>
        results.map((p) => {
          const user = users.find((u) => u.personId?.id === p.id)
          p.hasAccount = !!user
          if (user) {
            p.emailAccount = user.email
            p.uidAccount = user.id
            p.role = user.admin ? "admin" : user.planning ? "planning" : ""
          }
          return p
        })
      )
    })
  }

  _fieldsToContactDTO(fields: CrmContactFieldsDTO): any {
    const res = {} as CrmContactDTO
    ContactFields.forEach((fieldName) => (res[fieldName] = "xxx"))
    Object.entries(fields || {}).forEach(([fieldName, contact]) => (res[fieldName] = contact.value))
    return res
  }

  _formatRowDto(row: RowContactDTO<any>, keyRow: string): void {
    if (keyRow === "birthDay" && row.value?.seconds) {
      row.value = new Date(row.value?.seconds * 1000)
    }
    row.versioning = row.versioning || []
    row.versioning = row.versioning.map((v) => {
      for (const vKey in v) {
        const value = v[vKey] as any
        if (
          (typeof value === "object" && vKey.includes("date") && value?.seconds) ||
          (vKey === "value" && keyRow === "birthDay" && value?.seconds)
        ) {
          // @ts-ignore
          v[vKey] = new Date(value.seconds * 1000) // the type "timestamp" from firestore is not standard
        }
      }
      return v
    })
    row.versioning.sort((a, b) => (a.date < b.date ? 1 : -1))
  }

  updateRights(contactId: string, rights: RightsModelDTO): Promise<boolean> {
    return this.firestore
      .doc(this.config.collectionKey + `/${contactId}/rights/ALL`)
      .set(rights)
      .then(() => true)
      .catch((err) => this.handleError(err, null))
  }

  getRights(contactId: string): Promise<RightsModelDTO> {
    const url = this.config.collectionKey + `/${contactId}/rights/ALL`
    return this.firestore
      .doc(url)
      .get()
      .toPromise()
      .then((res) => {
        console.log(`!QUOTA! firestore getRights ${url} - ${contactId}`, 1)
        return res?.data() as RightsModelDTO
      })
      .catch((err) => this.handleError(err, null))
  }

  update(cmd: any /*partial object*/): Promise<PersonDTO> {
    throw new Error("personService.update should not be called directly")
  }

  async updateFieldName(contactId: string, fieldName: string, value: string): Promise<any> {
    return this.firestore
      .collection(this.config.collectionKey + `/${contactId}/fields/`)
      .doc(fieldName)
      .update({ label: value })
  }

  async updateFields(contactId: string, rows: CrmContactFieldsDTO, multiFieldNames: Array<string>): Promise<any> {
    try {
      await this.firestore
        .collection(this.config.collectionKey)
        .doc(contactId)
        .set({ multiFieldNames: multiFieldNames }, { merge: true })

      return Promise.all(
        Object.keys(rows).map((fieldName) => {
          const field = rows[fieldName]
          return this.firestore
            .collection(this.config.collectionKey + `/${contactId}/fields/`)
            .doc(fieldName)
            .set(field, { merge: true })
            .then(() => {
              if (this.cache[contactId]) {
                // updating cache
                const cacheItem = this.cache[contactId]
                cacheItem[fieldName] = field.value
              }
            })
        })
      )
        .then(async () => {
          // updating cache
          await this.setOfflineData("getOnlyNamesFields_" + contactId, null)
          await this.getOnlyNamesFields(contactId)
          return contactId
        })
        .catch((err) => this.handleError(err, false))
    } catch (err) {
      return this.handleError(err, false)
    }
  }

  createContact(rows: CrmContactFieldsDTO, currentUserGroupId: string): Promise<any> {
    return this.create({
      currentUserGroupId,
      _rights: {
        write: [this.myUuid],
        read: [this.myUuid],
        own: [this.myUuid],
      },
    })
      .then(async (contact) => {
        this.cache[contact.id] = contact
        await this.updateFields(contact.id || "", rows, [])
        this.cache[contact.id] = await this.toDTO(this.cache[contact.id], "")
        return contact.id
      })
      .catch((err) => this.handleError(err, false))
  }

  async getOnlyNamesFields(contactId: string): Promise<CrmContactFieldsDTO> {
    const url = this.config.collectionKey + `/${contactId}/fields/`
    // force caching!
    const cacheKey = "getOnlyNamesFields_" + contactId
    const cache = await this.getOfflineData(cacheKey, true /*force*/)
    if (cache) return cache

    return Promise.all(
      ["firstName", "lastName", "skills"].map((fieldName) =>
        this.firestore
          .collection(url)
          .doc(fieldName)
          .get()
          .toPromise()
          .catch(() => {
            /*possible erreur de droit!*/
          })
      )
    )
      .then((res) => {
        const row = {}
        res.forEach((raw) => {
          if (raw) {
            const dto = raw.data()
            this._formatRowDto(dto as any, raw.id)
            row[raw.id] = dto
          }
        })
        console.log(`!QUOTA! firestore getOnlyNamesFields ${url} - ${contactId}`, Object.keys(row).length)
        this.setOfflineData(cacheKey, row)
        return row
      })
      .catch((err) => this.handleError(err, null))
  }

  getMultiFieldsNames(contactId: string): Promise<Array<string>> {
    return this.firestore
      .collection(this.config.collectionKey)
      .doc(contactId)
      .get()
      .toPromise()
      .then((raw: any) => {
        console.log(`!QUOTA! firestore getMultiFieldsNames ${this.config.collectionKey}`, 1)
        return raw.data()?.multiFieldNames
      })
  }

  getFields(contactId: string, userId: string, all = false): Promise<CrmContactFieldsDTO> {
    const url = this.config.collectionKey + `/${contactId}/fields/`
    return this.firestore
      .collection(url, (ref) => {
        return all ? ref : ref.where("_rights.read", "array-contains", userId)
      })
      .get()
      .toPromise()
      .catch(() => {
        /*possible erreur de droit!*/
      })
      .then((res) => {
        const row = {}
        res?.forEach((raw) => {
          const dto = raw.data()
          this._formatRowDto(dto as any, raw.id)
          row[raw.id] = dto
        })
        console.log(`!QUOTA! firestore getFields ${url} - ${contactId}`, Object.keys(row).length)
        return row
      })
      .catch((err) => this.handleError(err, null))
  }

  // async migration_setAdminRightsForAllAndMigrate(): Promise<any> {
  //   const groupAdminId = "group_admin_id123456789"
  //   const persons = await super._getAllPaginated(this.config.collectionKey)
  //   for (let i = 0; i < persons.length; i++) {
  //     const person = persons[i]
  //     await this.updateRights(person.id, {
  //       [groupAdminId]: {
  //         read: true,
  //         own: true,
  //         write: true,
  //       } as any,
  //     })
  //
  //     const rows: CrmContactFieldsDTO = {}
  //     ;["lastName", "firstName", "nickName", "gender", "skills"].map((fieldName) => {
  //       const value = person[fieldName]
  //       rows[fieldName] = {
  //         value,
  //         category: "main",
  //         versioning: [
  //           {
  //             date: new Date(),
  //             who: "Migration CRM",
  //             value,
  //           },
  //         ],
  //       }
  //       if (person[fieldName]) {
  //         // person["__" + fieldName] = person[fieldName]
  //       }
  //       person[fieldName] = deleteField()
  //     })
  //     ;(person as any)["fullName"] = deleteField()
  //     ;(person as any)["fullName2"] = deleteField()
  //     ;(person as any)["skills_label"] = deleteField()
  //     await super.update(person)
  //     await this.updateFields(person.id, rows)
  //     console.log("person OK", person.id, `${i} / ${persons.length}`)
  //   }
  //   console.log("DONE!!")
  // }
}

export interface PersonDTO {
  id: string
  uuid?: string
  firstName: string
  lastName?: string
  gender: string
  nickName?: string
  fullName?: string
  fullName2?: string
  skills: Array<{ id: string }>

  nationality?: string
  fullAddress?: string
  birthDay?: Date
  birthTown?: string
  numSecu?: string
  holidayRef?: string

  skills_label: string // generated
  hasAccount: boolean // generated
  emailAccount?: string // generated
  uidAccount?: string // generated
  role?: string // generated
  email?: string

  //roles
  admin?: boolean
  rolePlanning?: boolean
}

export interface CrmContactFieldsDTO {
  [fieldName: string]: RowContactDTO<string>
}

export const ContactFields = [
  "lastName",
  "firstName",
  "nickName",
  "gender",
  "nationality",
  "fullAddress",
  "birthDay",
  "birthTown",
  "numSecu",
  "holidayRef",
  "skills",
  "birthRegion",
  "mail",
  "phone",
  "iban",
  "diet",
  "googleCalendarId",
  // when adding something here, you have to update the cloud function...
]

export interface CrmContactDTO {
  id?: string
  firstName: RowContactDTO<string>
  lastName: RowContactDTO<string>
  gender: RowContactDTO<string>
  nickName: RowContactDTO<string>

  googleCalendarId: RowContactDTO<string>

  nationality: RowContactDTO<string>
  fullAddress: RowContactDTO<string>
  birthDay: RowContactDTO<string>
  birthTown: RowContactDTO<string>
  numSecu: RowContactDTO<string>
  holidayRef: RowContactDTO<string>
  skills: RowContactDTO<Array<{ id: string }>>

  birthRegion: RowContactDTO<string>
  mail: RowContactDTO<string>
  phone: RowContactDTO<string>
  iban: RowContactDTO<string>
  diet: RowContactDTO<string>

  // Multi
  multiFieldNames?: Array<string>

  // when adding something here, you have to update the cloud function...

  readonly _rights?: { own: Array<string>; write: Array<string>; read: Array<string> }

  currentUserGroupId?: string // for creation only
}

export interface RowContactDTO<T> {
  value: T
  category: "main" | "nationality" | "contact" | "addresses" | "financial"
  label?: string
  versioning: Array<{
    value: T
    date: Date
    who: string
    // disabled?: boolean
    label?: string
    note?: string
    // dates??
  }>
  readonly _rights?: { own: Array<string>; write: Array<string>; read: Array<string> }
}

export type Rights = {
  own: boolean
  read: boolean
  write: boolean
}

export type RightsModelDTO = {
  [groupId: string]: {
    read: boolean
    own: boolean
    write: boolean
    custom: {
      [fieldId: string]: Rights
    }
  }
}
