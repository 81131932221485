import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ReactiveFormsModule } from "@angular/forms"

import { SharedModule } from "@shared"
import { AuthRoutingModule } from "./auth-routing.module"
import { LoginComponent } from "@app/auth/components/login/login.component"
import { AuthMenuComponent } from "./components/auth-menu/auth-menu.component"

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, SharedModule, AuthRoutingModule],
  declarations: [LoginComponent, AuthMenuComponent],
  exports: [AuthMenuComponent],
})
export class AuthModule {}
