import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ListComponent } from "@shared/component/list/list.component"
import { UntypedFormBuilder } from "@angular/forms"
import { Subscription } from "rxjs"
import { NotifService } from "@shared/service/notif.service"
import { ConfirmationService, MenuItem } from "primeng/api"
import { Dropdown } from "primeng/dropdown"
import { TableHeader } from "@shared/component/list/list.model"
import { ListViewDTO, ListViewService } from "@shared/component/views-list/list-views.service"

@Component({
  selector: "app-views-list",
  templateUrl: "./views-list.component.html",
  styleUrls: ["./views-list.component.scss"],
})
export class ViewsListComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild("dropdown") dropDownComponent!: Dropdown

  @Input() personId = "XX"
  @Input() isAdmin = false
  @Input() viewKey = ""
  @Input() listComponent?: ListComponent<any>
  form = this.formBuilder.group({
    view: [undefined, []],
  })
  popupCreatingVisible = false
  popupRenamingVisible = false
  popupColumnsVisible = false
  views: ListViewDTO[] = []
  selectedView?: ListViewDTO
  subs = new Subscription()
  viewNameInput = ""
  viewNameInput2 = ""
  columns: TableHeader[] = []
  publicPrivateOptions = [
    { label: "Publique", value: true },
    { label: "Privée", value: false },
  ]
  isPublic = false
  itemsDuplicate: MenuItem[] = []

  constructor(
    private confirmationService: ConfirmationService,
    private listViewService: ListViewService,
    private formBuilder: UntypedFormBuilder,
    private notifService: NotifService,
    private ngZone: NgZone
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  ngOnInit(): void {
    this.itemsDuplicate = [
      {
        label: "dans mes vues privées",
        icon: "pi pi-user",
        command: () => this.duplicateView(true),
      },
      {
        label: "dans les vues publiques",
        icon: "pi pi-lock",
        command: () => this.duplicateView(false),
        disabled: !this.isAdmin,
      },
    ]
    if (!this.viewKey) {
      throw new Error("viewKey should not be null!")
    }
    this.listViewService.setViewKey(this.viewKey)

    this.listViewService.getAllFromCache(this.personId).then((views) => {
      this.views = views
      const id = localStorage["performances_currentViewId"]
      const view = views.find((x) => x.id === id)
      if (view) {
        this.loadView(id)
      }
    })
    this.columns = this.listComponent?.data.headers || []
  }

  ngAfterViewInit(): void {
    if (!this.listComponent?.table) {
      throw new Error("listComponent should not be null!")
    }
    const table = this.listComponent?.table
    if (table.stateStorage !== "local") {
      throw new Error("stateStorage not compatible with ViewsListComponent (should be 'local')!")
    }
    this.subs.add(this.listComponent?.table?.onStateSave.subscribe((e) => this.updateView()))
  }

  updateView(force = false): void {
    const table = this.listComponent?.table
    if (this.selectedView?.id && table) {
      let needToUpdate = true
      try {
        const oldView = JSON.parse(this.selectedView.stringified)
        const newView = JSON.parse(localStorage[table.stateKey || ""])
        oldView.filters.global = ""
        newView.filters.global = ""
        if (JSON.stringify(oldView) === JSON.stringify(newView)) {
          needToUpdate = false // if the only chang is the global filter, we do not update the view (because it's not a part of the vue)
        }
      } catch (e) {}
      if (!force && (!needToUpdate || this.selectedView?.readonly)) return

      this.listViewService
        .update(
          {
            id: this.selectedView.id,
            name: this.selectedView.name,
            stringified: localStorage[table.stateKey || ""],
          },
          this.selectedView.private ? this.personId : ""
        )
        .then((view) => {
          const oldView = this.views.find((x) => x.id === view.id)
          if (oldView) {
            Object.assign(oldView, view)
          }
          this.ngZone.run(() => {
            this.notifService.displaySuccess(`Vue '${this.selectedView?.name}' mise à jour.`, "br")
          })
        })
    }
  }

  loadView(id: string): void {
    const view = this.views.find((x) => x.id === id)
    if (this.listComponent?.table && view) {
      this.dropDownComponent?.writeValue(view.id)
      const table = this.listComponent?.table
      this.selectedView = view
      if (!table) return
      localStorage[table.stateKey || ""] = view.stringified
      table.restoreState() // reading from localstorage
      table.columns = this.listComponent?.data.headers || []

      const filters = Object.keys(table.filters)
      const missingFilter = table.columns.filter((x) => !filters.includes(x.field))
      if (missingFilter.length > 0) {
        //it's happening when view stored in db are note complete
        console.log("MIMSSSSSSSSSSSSSSSING", missingFilter)
        missingFilter.forEach(
          (missingFilter) =>
            (table.filters[missingFilter.field] = { value: null, matchMode: missingFilter.type || "startsWith" })
        )
      }

      table.restoringFilter = false
      table.destroyStyleElement()
      table.restoreColumnWidths()
      setTimeout(() => this.listComponent?.table?.restoreColumnOrder(), 100) // WHY??
      table.onLazyLoad.emit(table.createLazyLoadMetadata()) // fetching data
      localStorage["performances_currentViewId"] = view.id
    } else {
      this.resetView()
    }
  }

  resetView(): void {
    if (this.listComponent?.table) {
      const table = this.listComponent?.table
      this.selectedView = undefined
      table.lazy = false // avoiding multiple call of onLazyLoad during clearing
      table.clear()
      table.columns = this.listComponent?.data.headers || []
      table.lazy = true
      table.destroyStyleElement()
      table.onLazyLoad.emit(table.createLazyLoadMetadata()) // fetching data
      table.saveState()
      localStorage["performances_currentViewId"] = ""
    }
  }

  createView(): void {
    if (this.viewNameInput && this?.listComponent?.table) {
      this.popupCreatingVisible = false
      this.listViewService
        .create(
          {
            name: this.viewNameInput,
            stringified: localStorage[this.listComponent.table.stateKey || ""],
          },
          this.isPublic ? "" : this.personId
        )
        .then((newView) => {
          this.views.unshift(newView)
          this.loadView(newView.id)
          this.viewNameInput = ""
        })
    }
  }

  duplicateView(isPrivate: boolean): void {
    this.listViewService
      .create(
        {
          name: this.selectedView?.name + "(dupliquée)",
          stringified: this.selectedView?.stringified,
        },
        isPrivate ? this.personId : ""
      )
      .then((newView) => {
        this.views.unshift(newView)
        this.loadView(newView.id)
        this.viewNameInput = ""
      })
  }

  deleting(): void {
    this.confirmationService.confirm({
      message: `Êtes vous certain de vouloir supprimer la vue '${this.selectedView?.name}' ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Supprimer",
      rejectLabel: "Annuler",
      accept: () => {
        const id = this.selectedView?.id
        if (id) {
          this.listViewService.delete2(id, this.selectedView?.private ? this.personId : "").then(() => {
            this.notifService.displaySuccess(`La vue '${this.selectedView?.name}' a bien été supprimée.`)
            const index = this.views.findIndex((x) => x.id === id)
            if (index >= 0) {
              this.views.splice(index, 1)
            }
            if (this.views.length > 0) {
              this.loadView(this.views[0].id)
            } else {
              this.dropDownComponent.writeValue("")
              this.resetView()
            }
          })
        }
      },
    })
  }

  renaming(): void {
    if (this.selectedView) {
      this.selectedView.name = this.viewNameInput2
      this.dropDownComponent.writeValue(this.selectedView.id) // update the selected name
    }
    this.updateView(true)
    this.popupRenamingVisible = false
  }

  columnIsDisplayed(field: string): boolean {
    return this.listComponent?.table?.columns?.find((x) => x.field === field)
  }

  toggleColumn(field: string): void {
    const table = this.listComponent?.table
    if (!table) {
      return
    }
    const col = table.columns?.find((x) => x.field === field)
    if (col) {
      table.columns = table.columns?.filter((x) => x.field !== field)
      if (table.filters[field]) {
        // @ts-ignore
        table.filters[field].value = null
      }
    } else {
      table.columns?.push(this.listComponent?.data.headers.find((x) => x.field === field))
    }
    table.saveState()
  }

  closePopupColumns(): void {
    this.popupColumnsVisible = false
    this.listComponent?.table?.onLazyLoad.emit(this.listComponent?.table?.createLazyLoadMetadata()) // fetching data
  }
}
