// inspired by https://raw.githubusercontent.com/angular/angularfire/master/src/auth-guard/auth-guard.ts
// fixed with https://github.com/angular/angularfire/issues/2986

//https://medium.com/echohub/angular-role-based-routing-access-with-angular-guard-dbecaf6cd685

import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router"
import { from, Observable, of, UnaryFunction } from "rxjs"
import { map, switchMap, take } from "rxjs/operators"
import { Auth, getAuth, user } from "@angular/fire/auth"
import { User } from "firebase/auth"
import { AuthService } from "@app/auth/auth.service"

export type AuthPipeGenerator = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => AuthPipe
export type AuthPipe = UnaryFunction<Observable<User | null>, Observable<boolean | string | any[]>>

export const loggedIn: AuthPipe = map((user) => !!user)

@Injectable({
  providedIn: "any",
})
export class AuthGuard {
  private readonly auth: Auth

  constructor(private router: Router, private authService: AuthService) {
    this.auth = getAuth()
  }

  canActivate = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authPipeFactory = (next.data.authGuardPipe as AuthPipeGenerator) || (() => loggedIn)
    return user(this.auth).pipe(
      take(1),
      authPipeFactory(next, state),
      switchMap((can) => {
        if (typeof can === "boolean") {
          if (can) {
            return from(
              this.authService.getUserInfo().then((user) => {
                if (user.rolePlanning) {
                  if (!next.data.roles?.includes(ROLES.PLANNING)) {
                    this.router.navigate(["/planning"])
                    return false
                  }
                } else if (next.data.roles?.includes(ROLES.ADMIN) && !user.admin) {
                  this.router.navigate(["/extern"])
                  return false
                }
                return can
              })
            )
          }
          return of(can)
        } else if (Array.isArray(can)) {
          return of(this.router.createUrlTree(can))
        } else {
          return of(this.router.parseUrl(can))
        }
      })
    )
  }
}

export const canActivate = (pipe: AuthPipeGenerator) => ({
  canActivate: [AuthGuard],
  data: { authGuardPipe: pipe },
})

export enum ROLES {
  ADMIN = "ADMIN",
  PLANNING = "PLANNING",
}
