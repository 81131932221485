import { Injectable } from "@angular/core"
import { PersonDTO } from "@app/pages/person/person.service"
import { DistributionDTO, PerformanceDTO, PerformanceService } from "@app/pages/performance/peformance.service"
import {
  ProgramConfigurationDTO,
  ProgramDTO,
  ProgramService,
  ProgramVersionDTO,
} from "@app/pages/program/program.service"
import { UnitDTO, UnitRoleDTO } from "@app/pages/unit/unit.service"
import { REFERENTIAL, ReferentialDTO, ReferentialService } from "@app/pages/referential/referential.service"
import { GenericDropDownView } from "@shared/component/dropdown-ref-firestore/dropdown-ref-firestore.component"
import { ProjectService } from "@app/pages/performance/project.service"

export interface DetailsDistrib {
  role: Array<{
    personId: string
    personName: string
  }> /*!! should be alone !!*/
  substitutes: Array<{
    personId: string
    personName: string
  }>
  roleName: string
}

@Injectable({
  providedIn: "root",
})
export class DistributionService {
  constructor(
    private performanceService: PerformanceService,
    private refService: ReferentialService,
    private programService: ProgramService,
    private projectService: ProjectService
  ) {}

  getRolesUnitsMap(programVersion?: Array<ProgramVersionDTO>): { [roleId: string]: UnitDTO } {
    const roleMap = {}
    programVersion?.forEach((x) =>
      x.units?.forEach((y) =>
        y.value.versions?.forEach((z) =>
          z.roles?.forEach((role) => {
            roleMap[role.id] = y.value
          })
        )
      )
    )
    return roleMap
  }

  getRolesMap(programVersion?: Array<ProgramVersionDTO>): { [roleId: string]: UnitRoleDTO } {
    const map = {}
    programVersion?.forEach((x) =>
      x.units?.forEach((y) =>
        y.value.versions?.forEach((z) =>
          z.roles?.forEach((role) => {
            map[role.id] = role
          })
        )
      )
    )
    return map
  }

  getUnitDistributionKey(unitId: string, programConfiguration?: ProgramConfigurationDTO): string {
    return `${unitId}##${this.getUnitVersionIdFromConfig(unitId, programConfiguration)}`
  }

  getDistribution(
    personId: string,
    unitId: string,
    distribution?: DistributionDTO,
    programConfiguration?: ProgramConfigurationDTO
  ): DistributionDTO | undefined {
    const obj = (distribution && distribution[this.getUnitDistributionKey(unitId, programConfiguration)]) || {}
    return obj[personId]
  }

  getUnitVersionIdFromConfig(unitId: string, programConfiguration?: ProgramConfigurationDTO): string {
    const conf = programConfiguration?.unitsConf
    if (conf) {
      return conf[unitId]
    }
    return ""
  }

  getTotalPoints(
    person: PersonDTO,
    performance?: PerformanceDTO,
    program?: ProgramDTO,
    rolesMap?: { [roleId: string]: UnitRoleDTO }
  ): { pts: number; ptsSub: number } {
    const rolesMap2 = rolesMap || this.getRolesMap(program?.versions)

    const distribution = performance?.distribution
    const programVersion = program?.versions?.find((x) => x.id === performance?.program_version_id)
    const programConfiguration = programVersion?.configurations?.find(
      (x) => x.id === performance?.program_configuration_id
    )

    return programVersion?.units
      ?.map((unit) => this.getDistribution(person.id, unit?.id || "", distribution, programConfiguration) as any)
      .filter((d) => d != undefined)
      .reduce(
        (acc, distrib: DistributionDTO) => {
          if (distrib.roleId) {
            const role = rolesMap2[distrib.roleId]
            acc.pts += +role?.points || 0
          }
          if (distrib.roleSubstituteId) {
            const role = rolesMap2[distrib.roleSubstituteId]
            acc.ptsSub += (+role?.points || 0) * (distrib?.coefSubstitute || 1)
          }
          return acc
        },
        {
          pts: 0,
          ptsSub: 0,
        }
      )
  }

  getPersonDistribution(
    personId: string,
    performance: PerformanceDTO,
    onlyPieces = false
  ): Promise<{
    role: Array<{ unit: string; role: string; roleId: string }>
    substitute: Array<{ unit: string; role: string; roleId: string }>
  }> {
    const emptyResult = Promise.resolve({ role: [], substitute: [] })
    const distribution = performance?.distribution
    const programId = performance?.program?.id
    if (!programId || !distribution) {
      return emptyResult
    }
    let refUnits: Array<ReferentialDTO> = []
    return this.refService.getAllFromCache(REFERENTIAL.unity_type).then((res) => {
      refUnits = res
      return this.programService.get(programId, true).then((program) => {
        const programVersion = program?.versions?.find((x) => x.id === performance?.program_version_id)
        const programConfiguration = programVersion?.configurations?.find(
          (x) => x.id === performance?.program_configuration_id
        )
        if (programVersion && programConfiguration) {
          const roleMap = this.getRolesMap([programVersion])
          const roleUnitMap = this.getRolesUnitsMap([programVersion])
          return programVersion?.units
            ?.map((unit) => this.getDistribution(personId, unit?.id || "", distribution, programConfiguration) as any)
            .filter((d) => d != undefined)
            .reduce(
              (acc, distrib) => {
                if (distrib.roleId) {
                  const id = distrib.roleId
                  const unit = roleUnitMap[id]
                  const refUnit = refUnits.find((x) => x?.id === unit?.type?.id) as any
                  if (refUnit?.visible && (!onlyPieces || (onlyPieces && refUnit.id === "PIECE")) /*FIXME*/) {
                    acc.role.push({ unit: unit?.name, role: roleMap[id]?.name, roleId: id })
                  }
                }
                if (distrib.roleSubstituteId) {
                  const id = distrib.roleSubstituteId
                  const unit = roleUnitMap[id]
                  const refUnit = refUnits.find((x) => x?.id === unit?.type?.id) as any
                  if (refUnit?.visible && (!onlyPieces || (onlyPieces && refUnit.id === "PIECE")) /*FIXME*/) {
                    acc.substitute.push({ unit: unit?.name, role: roleMap[id]?.name, roleId: id })
                  }
                }
                return acc
              },
              {
                role: [],
                substitute: [],
              }
            )
        }
        return emptyResult
      })
    })
  }

  getDistributionDetails(
    performance: PerformanceDTO,
    personsFirstNameMap: { [personId: string]: string }
  ): Promise<
    Array<{
      unitId: string
      unitName: string
      details: Array<DetailsDistrib>
    }>
  > {
    const emptyResult = Promise.resolve([])
    const distribution = performance?.distribution
    const programId = performance?.program?.id
    if (!programId || !distribution) {
      return emptyResult
    }
    let refUnits: Array<ReferentialDTO> = []
    return this.refService.getAllFromCache(REFERENTIAL.unity_type).then((res) => {
      refUnits = res
      return this.programService.get(programId, false /*not working otherwise*/).then((program) => {
        const programVersion = program?.versions?.find((x) => x.id === performance?.program_version_id)
        const programConfiguration = programVersion?.configurations?.find(
          (x) => x.id === performance?.program_configuration_id
        )
        if (programVersion && programConfiguration) {
          const rolesMap = this.getRolesMap([programVersion])
          return programVersion?.units?.map((unit) => {
            const d = distribution[this.getUnitDistributionKey(unit?.id, programConfiguration)] || {}

            let results: Array<DetailsDistrib> = []
            const roleIds = {}
            const mapRoles = {}
            Object.keys(d).forEach((personId) => {
              const obj = d[personId]
              const firstName = personsFirstNameMap[personId] || ""
              const roleId = obj.roleId || obj.roleSubstituteId || ""
              const isSubstitute = !!obj.roleSubstituteId
              if (roleId) {
                mapRoles[roleId] = mapRoles[roleId] || {
                  roleName: rolesMap[roleId]?.name,
                  role: [],
                  substitutes: [],
                }
                if (isSubstitute) {
                  mapRoles[roleId].substitutes.push({
                    personName: firstName,
                    personId,
                  })
                } else {
                  mapRoles[roleId].role.push({
                    personName: firstName,
                    personId,
                  })
                }
                roleIds[roleId] = true
              }
            })
            results = Object.values(mapRoles)

            const uniVersion = unit.value?.versions?.find((x) => x.id === programConfiguration?.unitsConf[unit.id])
            if (uniVersion) {
              uniVersion.roles.forEach((role) => {
                if (!roleIds[role.id])
                  // if a role is not distributed, personName is empty
                  results.push({
                    role: [
                      {
                        personName: "",
                        personId: "",
                      },
                    ],
                    roleName: role.name,
                    substitutes: [],
                  })
              })
            }
            return {
              unitId: unit?.value?.id,
              unitName: unit?.value?.name,
              details: results,
            }
          })
        }
        return emptyResult
      })
    })
  }

  importDistribution(
    currentPerformance: PerformanceDTO,
    performanceToImport: PerformanceDTO,
    personToImportChoices: { [personId: string]: GenericDropDownView }
  ): Promise<void> {
    if (!currentPerformance || !performanceToImport) throw new Error("importDistribution, wrong parameters")

    return this.programService.get(currentPerformance.program?.id || "").then((program) => {
      currentPerformance.distribution = currentPerformance?.distribution || {}
      const distrib = currentPerformance.distribution

      performanceToImport.distribution = performanceToImport?.distribution || {}
      const distribToImport = performanceToImport?.distribution

      const programVersion = program.versions?.find((x) => x.id === currentPerformance.program_version_id)
      const programConf = programVersion?.configurations?.find(
        (x) => x.id === currentPerformance.program_configuration_id
      )
      const programConfToImport = programVersion?.configurations?.find(
        (x) => x.id === performanceToImport?.program_configuration_id
      )
      const units = programVersion?.units || []
      return this.projectService.getPersonDistribution(currentPerformance).then((currentPersons) => {
        units.forEach((unit) => {
          const unitIdWithUnitVersionId = this.getUnitDistributionKey(unit.id, programConf)
          const unitIdWithUnitVersionIdToImport = this.getUnitDistributionKey(unit.id, programConfToImport)
          const distToImport = distribToImport[unitIdWithUnitVersionId] || {}
          currentPersons.forEach((person) => {
            const personIdToImport = personToImportChoices[person.id]?.id
            if (personIdToImport) {
              // if no choice, the distribution for this person should not be modified
              distrib[unitIdWithUnitVersionId] = distrib[unitIdWithUnitVersionId] || {}
              const d = distToImport[personIdToImport]
              if (d && unitIdWithUnitVersionId === unitIdWithUnitVersionIdToImport) {
                // if unit version in both current AND toImport conf
                distrib[unitIdWithUnitVersionId][person.id] = { ...d } // cloning distribution
              } else {
                delete distrib[unitIdWithUnitVersionId][person.id]
              }
            }
          })
        })
      })
    })
  }
}
