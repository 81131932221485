<p-multiSelect
  [options]="options"
  [(ngModel)]="selectedOptions"
  (onChange)="onChangeEvent($event)"
  optionLabel="label"
  optionDisabled="optionDisabled"
  placeholder="Sélectionner..."
  [selectedItemsLabel]="selectedItemsLabel"
  dataKey="id"
  [maxSelectedLabels]="maxSelectedLabels"
  [filter]="filter"
  [filterValue]="filterValue"
  [disabled]="formDisabled"
  [required]="required"
  appendTo="body"
></p-multiSelect>
