<p-dialog
  [(visible)]="popupUpdatePassword"
  [modal]="true"
  header="Mettre à jour son mot de passe"
  [style]="{ width: '500px' }"
>
  <div class="content text-center dialog-mobile">
    <app-form-field label="Nouveau mot de passe" *ngIf="!shouldReauth">
      <input pInputText [(ngModel)]="newPassword" required minlength="6" />
    </app-form-field>
    <div *ngIf="shouldReauth" style="color: red">
      Vous devez de nouveau vous identifier avant de pouvoir effectuer cette action.
    </div>
  </div>
  <div class="ui-dialog-footer text-center mt-4">
    <p-button (click)="popupUpdatePassword = false" styleClass="p-button-outlined">Annuler</p-button>
    <p-button
      *ngIf="!shouldReauth"
      (click)="newPassword.length >= 6 && updatePassword()"
      [disabled]="newPassword.length < 6"
      >Mettre à jour
    </p-button>
    <p-button *ngIf="shouldReauth" (click)="logout()">S'identifier à nouveau</p-button>
    <div class="mt-2"><i>Il doit contenir au moins 6 caractères</i></div>
  </div>
</p-dialog>
<div
  [class]="{ account: true, disconnected: authService.isOffline }"
  (click)="menu.toggle($event); updateMenu()"
  [title]="(authService.currentUser?.fullName || '') + ' - ' + authService.currentUser?.email"
>
  <span class="avatar">{{
    (authService?.currentUser?.fullName || authService?.currentUser?.email || "?")[0].toUpperCase()
  }}</span>
</div>
<p-menu #menu [popup]="true" styleClass="menu-account" [model]="accountItemsConnected" appendTo="body"></p-menu>
