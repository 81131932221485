<div class="flex flex-column align-items-center container">
  <form [formGroup]="fg" (keydown.enter)="login()" style="height: 380px" class="text-center">
    <h2>Connexion</h2>
    <h3>BO 3e-etage</h3>
    <div class="p-field">
      <label class="p-sr-only">Email</label>
      <input pInputText placeholder="Email" type="text" formControlName="username" required autocomplete="username" />
    </div>
    <div class="p-field" *ngIf="!forgottenPwd">
      <label class="p-sr-only">Mot de passe</label>
      <input
        pInputText
        placeholder="Mot de passe"
        type="password"
        formControlName="password"
        required
        autocomplete="current-password"
      />
    </div>

    <button
      pButton
      [label]="forgottenPwd ? 'Réinitialiser mon mot de passe par mail' : 'Se connecter'"
      (click)="login()"
      [style.opacity]="fg?.invalid ? 0.5 : 1"
    ></button>

    <div class="xhr-error mt-2" *ngIf="error">{{ error }}</div>
    <div class="successMsg mt-2" *ngIf="successMsg">{{ successMsg }}</div>
    <!--    <div class="pwd mt-2" (click)="forgottenPwd = !forgottenPwd">-->
    <!--      {{ forgottenPwd ? "Se connecter" : "Mot de passe oublié" }}-->
    <!--    </div>-->
  </form>
</div>
