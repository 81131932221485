import { env } from "./.env"

const firebaseConfig = {
  apiKey: "AIzaSyCONTip8Ct_TEFPRweCXND-rtsyUHAn6PU",
  authDomain: "etage-dev-d35aa.firebaseapp.com",
  projectId: "etage-dev-d35aa",
  storageBucket: "etage-dev-d35aa.appspot.com",
  messagingSenderId: "697260748310",
  appId: "1:697260748310:web:26fa3e6e0a06d4a90c0f37",
  measurementId: "G-80ZXQ4Y5KC",
  contractGenerationUrl: "https://us-central1-etage-dev-d35aa.cloudfunctions.net/getPdfContract",
}

export const environment = {
  production: false,
  version: env.npm_package_version,
  defaultLanguage: "fr-FR",
  supportedLanguages: ["en-US", "fr-FR"],
  title: "3e etage",
  firebaseConfig,
}
