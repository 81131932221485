import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { NotifService } from "@shared/service/notif.service"
import { AuthService } from "@app/auth"
import * as localforage from "localforage"

@Component({
  selector: "app-auth-menu",
  templateUrl: "./auth-menu.component.html",
  styleUrls: ["./auth-menu.component.scss"],
})
export class AuthMenuComponent implements OnInit {
  version = "0.33"
  accountItemsConnected = [
    {
      label: "Connecté en tant que",
      disabled: true,
    },
    {
      label: "Dynamically updated",
      icon: "pi pi-user",
      disabled: true,
    },
    /*{
      label: "Modifier mon mot de passe",
      icon: "pi pi-pencil",
      command: () => {
        this.popupUpdatePassword = true
      },
    },*/
    {
      label: `<div class="flex align-center"><div>Me déconnecter</div><div class="ml-auto text-gray-400 text-xs">${this.version}</div></div>`,
      escape: false,
      icon: "pi pi-sign-out",
      version: "0.2",
      command: () => {
        this.logout()
      },
    },
  ]

  popupUpdatePassword = false
  newPassword = ""
  processing = false
  shouldReauth = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notifService: NotifService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {}

  logout(): void {
    if (navigator.onLine === true) {
      localforage.clear()
    }
    this.authService.logout()
    // .then(() => this.router.navigate(["/login"], { replaceUrl: true }))
  }

  updatePassword(): void {
    this.shouldReauth = false
    this.processing = true
    this.authService
      .updatePassword(this.newPassword)
      .then(() => {
        this.notifService.displaySuccess("Votre mot de passe a été mis à jour!")
        this.popupUpdatePassword = false
        this.newPassword = ""
      })
      .catch((err) => {
        if (err.code === "auth/requires-recent-login") {
          this.notifService.displayError("Action impossible")
          this.shouldReauth = true
        } else {
          this.notifService.displayError(err.message)
        }
      })
      .finally(() => (this.processing = false))
  }

  updateMenu(): void {
    this.accountItemsConnected[1].label = this.authService.currentUser?.fullName
      ? `${this.authService.currentUser?.fullName}  (${this.authService.currentUser?.email})`
      : this.authService.currentUser?.email || "??"
  }
}
