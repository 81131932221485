export function nullIfBlank(str: string | null | undefined): string | null {
  return isBlank(str) ? null : str!
}

/**
 * @param str
 * @return true if null, empty or only contains spaces
 */
export function isBlank(str: string | null | undefined): boolean {
  return !str || /^\s*$/.test(str)
}

/**
 * @param str
 * @return true if not null, not empty and does not contain only spaces
 */
export function isNotBlank(str: string | null | undefined): boolean {
  return !isBlank(str)
}
