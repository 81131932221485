<p-dropdown
  #dropDown
  [options]="options"
  [optionValue]="optionValue"
  [(ngModel)]="inputValue"
  scrollHeight="350px"
  appendTo="body"
  [optionLabel]="optionLabel"
  [editable]="true"
  (onBlur)="blur()"
  (onChange)="timeChange($event)"
></p-dropdown>
