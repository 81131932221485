import { Component, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"

import { ActivatedRoute, Router } from "@angular/router"
import { environment } from "@env/environment"
import { UntilDestroy } from "@ngneat/until-destroy"
import { showFormErrors } from "@shared/util/form.util"
import { AuthService } from "@app/auth/auth.service"

@UntilDestroy()
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version
  error = ""
  successMsg = ""
  fg = new UntypedFormGroup({})
  isLoading = false
  forgottenPwd = false

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fg = this.fb.group({
      username: ["", [Validators.email]],
      password: ["", null],
    })
  }

  login() {
    this.successMsg = ""
    this.error = ""
    if (this.fg.get("username")?.valid && this.forgottenPwd) {
      this.authService
        .resetPassword(this.fg.get("username")?.value)
        .then(() => {
          this.successMsg = "Un email a été envoyé."
          this.forgottenPwd = false
        })
        .catch((err) => {
          console.error(err)
          this.error = err?.code === "auth/user-not-found" ? "L'email saisi est invalide" : err?.message
        })
      return
    }

    if (this.fg.valid) {
      this.isLoading = true
      this.authService
        .login(this.fg.value)
        .then((data) => {
          // console.log("info", data)
          const url = localStorage["initialUrl"]
          this.router.navigate([url && !url.startsWith("/login") ? url : "/"], { replaceUrl: true })
        })
        .catch((error) => {
          console.debug("Login error", error)
          this.error = "Identifiant ou mot de passe invalide."
        })
        .finally(() => {
          this.fg.markAsPristine()
          this.isLoading = false
        })
    } else {
      showFormErrors(this.fg)
    }
  }
}
