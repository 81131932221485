import { Component, OnInit } from "@angular/core"
import { Utils } from "@shared/util/utils"

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
})
export class MainLayoutComponent implements OnInit {
  title = "admin-front"
  sideBarShown = false
  isMobile = Utils.isMobile()

  constructor(/*public authService: AuthService*/) {
    this.sideBarShown = localStorage.getItem("sidebarOpened") === "true"
  }

  onActivate(): void {
    // window.scroll(0, 0)
  }

  ngOnInit(): void {}

  sideBarOpened(bool: boolean): void {
    this.sideBarShown = bool
  }
}
