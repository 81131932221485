import { Injectable } from "@angular/core"
import { FireStoreService } from "@shared/service/fire-store.service"
import { PersonDTO, PersonService } from "@app/pages/person/person.service"
import { REFERENTIAL, ReferentialDTO, ReferentialService } from "@app/pages/referential/referential.service"
import { ProgramDTO, ProgramService } from "@app/pages/program/program.service"
import { arrayRemove, arrayUnion } from "@angular/fire/firestore"
import { AuthService } from "@app/auth"
import { UnitDTO } from "@app/pages/unit/unit.service"

export interface ExternViewInfo {
  list: Array<PerformanceInfoExtern>
  persons: { [personId: string]: string /*firstName*/ }
}

@Injectable({
  providedIn: "root",
})
export class PerformanceService extends FireStoreService<PerformanceDTO> {
  getPerformancesByProjectsIdCloudFunction = this.functions.httpsCallable("getPerformancesByProjectsId")
  getProjectsByDateCloudFunction = this.functions.httpsCallable("getProjectsCodeByDate")

  constructor(
    private personService: PersonService,
    private programService: ProgramService,
    private refService: ReferentialService,
    private authService: AuthService
  ) {
    super()
    this.init({
      collectionKey: "performances",
      fullTextFields: [
        "season_label",
        "code_label",
        "status_label",
        "type_label",
        "town",
        "country",
        "program_label",
        "director_label",
        "artistic_director_label",
        "video_notes",
        "note",
      ],
      sorting: {
        field: "date",
        order: "desc",
      },
    })
  }

  public formatName(dto: PersonDTO | undefined, personId: string): string {
    return dto ? `${dto.fullName}` : personId ? "xxx xxx" /*no rights*/ : ""
  }

  toDTO(dto: PerformanceDTO, action: string): Promise<PerformanceDTO> {
    // TO BE IMPROVED

    return Promise.all([
      this.personService.getAllFromCache(),
      this.refService.getAllFromCache(REFERENTIAL.currency),
      this.refService.getAllFromCache(REFERENTIAL.perf_type),
      this.refService.getAllFromCache(REFERENTIAL.perf_status),
      this.refService.getAllFromCache(REFERENTIAL.project_code),
      this.refService.getAllFromCache(REFERENTIAL.season),
      this.refService.getAllFromCache(REFERENTIAL.perf_status_unexpected),
      this.refService.getAllFromCache(REFERENTIAL.video_storage),
      this.refService.getAllFromCache(REFERENTIAL.skills),
      this.programService.getAllFromCache(),
      // action === FireStoreService.ACTION.GET
      //   ? dto.program?.id
      //     ? this.programService.get(dto.program?.id).then((x) => [x])
      //     : Promise.resolve([])
      //   : this.programService.getAllFromCache(),
    ]).then((refs) => {
      const [
        refPersons,
        refCurrencies,
        refTypes,
        refStatus,
        refCodes,
        refSeasons,
        refStatusUnexpected,
        refVideos,
        refSkills,
        refPrograms,
      ] = refs
      dto.season_label = refSeasons.find((x: ReferentialDTO) => x.id === dto.season?.id)?.name || "?"
      dto.code_label = refCodes.find((x: ReferentialDTO) => x.id === dto.code?.id)?.name || "?"
      dto.type_label = refTypes.find((x: ReferentialDTO) => x.id === dto.type?.id)?.name || "?"
      dto.status_label = refStatus.find((x: ReferentialDTO) => x.id === dto.status?.id)?.name || "-"
      dto.status_label_color = refStatus.find((x: ReferentialDTO) => x.id === dto.status?.id)?.color || ""
      dto.status_unexpected_label =
        refStatusUnexpected.find((x: ReferentialDTO) => x.id === dto.status_unexpected?.id)?.name || ""

      const program = refPrograms.find((x: ProgramDTO) => x.id === dto.program?.id)
      const programVersion = program?.versions?.find((x) => x.id === dto.program_version_id)
      const programConf = programVersion?.configurations?.find((x) => x.id === dto.program_configuration_id)
      dto.program_label = program?.name
      dto.program_official_label = program?.name_official
      dto.program_version_label = programVersion?.name
      dto.program_configuration_label = programConf?.name
      dto.name_label = `${dto.code_label} - ${dto.date?.toLocaleDateString("fr-FR")}`
      const skillIdInterprete = this.refService.getDancerSkillId(refSkills)
      dto.nb_dancer_calculated = Object.values(dto.personsSkills || {}).filter((ids) =>
        ids.includes(skillIdInterprete)
      ).length

      dto.artistic_director_label = this.formatName(
        refPersons.find((x: PersonDTO) => x.id === dto.artistic_director?.id),
        dto.artistic_director?.id
      )
      dto.director_label = this.formatName(
        refPersons.find((x: PersonDTO) => x.id === dto.director?.id),
        dto.director?.id
      )
      dto.currency_label = refCurrencies.find((x: ReferentialDTO) => x.id === dto.currency?.id)?.name
      dto.video_storage_label = dto.video_storage
        ?.map((x: any) => refVideos.find((y) => y.id === x.id)?.name)
        .join(", ")
      dto.seniority_ignore_label = dto.seniority_ignore ? "OUI" : ""
      return dto
    })
  }

  getPerformancesByProject(projectCode: string): Promise<Array<PerformanceDTO>> {
    const projectRef = this.firestore.collection("ref-project-code").doc(projectCode).ref
    return this.firestore
      .collection(this.config.collectionKey, (ref) => ref.where("code", "==", projectRef))
      .get()
      .toPromise()
      .then((res) => {
        const items: Array<Promise<PerformanceDTO>> = []
        res?.forEach((r) => items.push(this._toDTO(r, FireStoreService.ACTION.GET)))
        console.log(`!QUOTA! firestore reading - getPerformancesByProject ${this.config.collectionKey}`, items.length)
        return Promise.all(items).then((items) => {
          items.sort((a, b) => (a.date > b.date ? 1 : -1))
          return items
        })
      })
      .catch((err) => this.handleError(err, null))
  }

  getPerformancesInfoForExternId(
    personId: string,
    hydrating: (data: ExternViewInfo) => Promise<any>
  ): Promise<ExternViewInfo> {
    const cacheKey = `${this.config.collectionKey}#${personId}#getPerformancesInfoForExternId`
    return this.callCloudFunction(
      () => this.getPerformancesByProjectsIdCloudFunction({ personId }).toPromise(),
      cacheKey,
      true,
      hydrating
    )
  }

  async getProgramsAndPerformancesByUnit(unit: UnitDTO): Promise<
    Array<{
      program: ProgramDTO
      performance: PerformanceDTO
    }>
  > {
    const programs = await this.programService.getAllFromCache()
    const performances = await this.getAllFromCache()
    const filteredProgramVersion: Array<string> = []
    const filteredPrograms = programs.filter((p) => {
      const versions = p.versions?.filter((v) => v.units?.find((u) => u.value?.id === unit.id))
      versions?.forEach((version) => filteredProgramVersion.push(version.id))
      return versions?.length > 0
    })
    const res: Array<{
      program: ProgramDTO
      performance: PerformanceDTO
    }> = []
    filteredPrograms.map((program) =>
      performances
        .filter((pe) => pe.program?.id === program.id && filteredProgramVersion.includes(pe.program_version_id))
        .forEach((performance) => {
          res.push({
            program,
            performance,
          })
        })
    )
    res.sort((a, b) => (a.performance.date > b.performance.date ? -1 : 1))
    return res
  }

  getPerformancesByProgram(programId: string): Promise<Array<PerformanceDTO>> {
    const programRef = this.firestore.collection("programs").doc(programId).ref
    return this.firestore
      .collection(this.config.collectionKey, (ref) => ref.where("program", "==", programRef))
      .get()
      .toPromise()
      .then((res) => {
        const items: Array<Promise<PerformanceDTO>> = []
        res?.forEach((r) => items.push(this._toDTO(r, FireStoreService.ACTION.GET)))
        console.log(
          `!QUOTA! firestore reading - getPerformancesByProgram ${this.config.collectionKey} (${programId})`,
          items.length
        )
        return Promise.all(items).then((items) => {
          items.sort((a, b) => (a.date < b.date ? 1 : -1))
          return items
        })
      })
      .catch((err) => this.handleError(err, null))
  }

  getPerformancesWithDistribution(): Promise<Array<PerformanceDTO>> {
    return this.firestore
      .collection(this.config.collectionKey, (ref) => ref.where("distribution", "!=", null))
      .get()
      .toPromise()
      .then((res) => {
        const items: Array<Promise<PerformanceDTO>> = []
        res?.forEach((r) => items.push(this._toDTO(r, FireStoreService.ACTION.GET)))
        console.log(
          `!QUOTA! firestore reading - getPerformancesWithDistribution ${this.config.collectionKey}`,
          items.length
        )
        return Promise.all(items).then((items) => {
          items.sort((a, b) => (a.date < b.date ? 1 : -1))
          return items
        })
      })
      .catch((err) => this.handleError(err, null))
  }

  getProjectsByDate(dateStart: Date, dateEnd: Date): Promise<Array<string>> {
    if (this.authService.isAdmin()) {
      // performances are better this way...
      return this.firestore
        .collection(this.config.collectionKey, (ref) => ref.where("date", ">", dateStart).where("date", "<=", dateEnd))
        .get()
        .toPromise()
        .then((res) => {
          let items: Array<Promise<PerformanceDTO>> = []
          res?.forEach((r) => {
            const obj = r.data() as any
            items.push(obj.code.id)
          })
          items = [...new Set(items)]
          console.log(`!QUOTA! firestore reading - getProjectsByDate ${this.config.collectionKey}`, items.length)
          return items
        })
        .catch((err) => this.handleError(err, null))
    }

    const cacheKey = `${
      this.config.collectionKey
    }#${dateStart.toISOString()}#${dateEnd.toISOString()}#getProjectsByDateCloudFunction`
    return this.callCloudFunction(
      () => this.getProjectsByDateCloudFunction({ dateStart, dateEnd }).toPromise(),
      cacheKey
    )
  }

  getPerformancesByDate(dateStart: Date, dateEnd: Date): Promise<Array<PerformanceDTO>> {
    return this.firestore
      .collection(this.config.collectionKey, (ref) => ref.where("date", ">=", dateStart).where("date", "<=", dateEnd))
      .get()
      .toPromise()
      .then((res) => {
        const promises: Array<Promise<any>> = []
        // const items: Array<PerformanceDTO> = []
        res?.forEach((r) => {
          promises.push(this._toDTO(r, FireStoreService.ACTION.GET))
        })
        console.log(`!QUOTA! firestore reading - getPerformancesByDate ${this.config.collectionKey}`, promises.length)
        return Promise.all(promises)
      })
      .catch((err) => this.handleError(err, null))
  }

  addSkillPerson(performanceId: string, personId: string, skill: string): Promise<boolean> {
    return this.firestore
      .collection(this.config.collectionKey)
      .doc(performanceId)
      .set({ personsSkills: { [personId]: arrayUnion(skill) } }, { merge: true })
      .then(() => {
        console.log("!QUOTA! firestore writing addSkillPerson", 1)
        const cache = this.cache[performanceId]
        if (cache) {
          cache.personsSkills = cache.personsSkills || {}
          const cacheSkills = cache.personsSkills
          cacheSkills[personId] = cacheSkills[personId] || []
          cacheSkills[personId].push(skill)
        }
        return true
      })
      .catch((err) => this.handleError(err, false))
  }

  deleteSkillPerson(performanceId: string, personId: string, skill: string): Promise<boolean> {
    return this.firestore
      .collection(this.config.collectionKey)
      .doc(performanceId)
      .set({ personsSkills: { [personId]: arrayRemove(skill) } }, { merge: true })
      .then(() => {
        const cache = this.cache[performanceId]
        if (cache) {
          cache.personsSkills = cache.personsSkills || {}
          const cacheSkills = cache.personsSkills
          const array = cacheSkills[personId] || []
          const index = array.findIndex((x: string) => x === skill)
          if (index >= 0) {
            array.splice(index, 1)
          }
        }
        return true
      })
      .catch((err) => this.handleError(err, false))
  }

  getDancersIds(performance: PerformanceDTO): Promise<Array<string>> {
    return this.refService.getDancerSkillId2().then((dancerSkillId) => {
      return Object.keys(performance?.personsSkills || {}).filter((personId) =>
        (performance?.personsSkills[personId] || []).find((skillId) => skillId === dancerSkillId)
      )
    })
  }

  getPerformancesWithProgramVersion(programVersionId: string): Promise<Array<PerformanceDTO>> {
    return this.firestore
      .collection(this.config.collectionKey, (ref) => ref.where("program_version_id", "==", programVersionId))
      .get()
      .toPromise()
      .then((res) => {
        const items: Array<Promise<PerformanceDTO>> = []
        res?.forEach((r) => items.push(this._toDTO(r, FireStoreService.ACTION.GET)))
        console.log(
          `!QUOTA! firestore reading - getPerformancesWithProgramVersion ${this.config.collectionKey}`,
          items.length
        )
        return Promise.all(items).then((items) => {
          items.sort((a, b) => (a.date < b.date ? 1 : -1))
          console.log("getPerformancesWithProgramVersion", items)
          return items
        })
      })
      .catch((err) => this.handleError(err, null))
  }
}

export interface DistributionDTO {
  roleId?: string
  roleSubstituteId?: string
  coefSubstitute?: number
}

export interface PerformanceDTO {
  id: string
  status_unexpected: { id: string }
  season: { id: string }
  code: { id: string }
  type: { id: string }
  date: Date
  month: string
  year: string
  hour: string
  town: string
  department: string
  country: string
  location: string
  nb_dancer: string
  status: { id: string }
  program: { id: string }
  program_version_id: string
  program_configuration_id: string
  note_budget: string
  currency: { id: string }
  amount: string
  amount_note: string
  additional_costs: string
  V: string
  H: string
  R: string
  gauge: string
  artistic_director: { id: string }
  director: { id: string }
  DA: string
  note: string
  video_storage: [{ id: string }]
  video_notes: string
  seniority_ignore: boolean
  cessionDA: number
  AV_DD: number

  distribution?: { [unitIdWithUnitVersionId: string]: { [personId: string]: DistributionDTO } }
  personsSkills: { [personId: string]: Array<string> /*skillId*/ }
  payment: {
    refValues: { [key: string]: string }
    persons: { [personId: string]: { [key: string]: string } }
  }
  communication: {
    [personId: string]: {
      planning: boolean
      tour: boolean
      programing: boolean
      roles: boolean
      payment_min: boolean
      payment_real: boolean
      status?: { id: string }
      note: string
      program_title: boolean
      status_perf: boolean
      status_person: boolean
      external_note: string
      external_note_prio: string
    }
  }
  general_note: string

  // Calculated fields
  status_unexpected_label?: string
  season_label?: string
  name_label?: string
  code_label?: string
  status_label?: string
  status_label_color?: string
  status_label_unexpected?: string
  type_label?: string
  program_label?: string
  program_official_label?: string
  program_version_label?: string
  program_configuration_label?: string
  director_label?: string
  artistic_director_label?: string
  currency_label?: string
  video_storage_label?: string
  nb_dancer_calculated?: number
  seniority_ignore_label?: string
}

export interface PerformanceInfoExtern {
  distribution?: { [unitIdWithUnitVersionId: string]: { [personId: string]: DistributionDTO } }
  program: { id: string }
  program_version_id: string
  program_configuration_id: string

  date: string // iso format
  dateStr: string
  town: string
  code: { id: string }
  personsSkills: { [personId: string]: Array<string> /*skillId*/ }
  season: { id: string }
  paymentMin: string
  paymentReal: string
  showTour: boolean
  roles: boolean
  programing: boolean
  showPlanning: boolean
  program_title: boolean
  note: string
  note_prio: string
  general_note: string
  myStatus?: { id: string }
  status_perf?: string
  type_perf?: string
  persons: { [personId: string]: string /*fisrtName*/ }
  id: string
}
