import { Injectable } from "@angular/core"
import { DocumentSnapshot } from "@angular/fire/compat/firestore"
import { FireStoreService } from "@shared/service/fire-store.service"
import { EventDTO } from "@app/pages/planning/calendar.service"

@Injectable({
  providedIn: "root",
})
export class EventService extends FireStoreService<EventDTO> {
  cacheAll: Array<EventDTO> = []

  constructor() {
    super()
  }
  setProjectCode(code: string): void {
    this.init({
      collectionKey: `projects/${code}/events`,
      fullTextFields: [],
    })
  }

  get(id: string): Promise<EventDTO> {
    return super.get(id, true)
  }

  exportAllToJson(code = ""): any {
    if (!code) throw new Error("'code' should not be empty")
    this._resetCache() // otherwise the backup is sometimes not working properly :(
    return this.getAllFromCache(code).then((objects) => {
      this.prepareSerializingFirestoreData(objects)
      return objects
    })
  }

  _getAllPaginated(
    collectionKey: string,
    limit?: number,
    startAfter?: DocumentSnapshot<any>
  ): Promise<Array<EventDTO>> {
    const code = this.config.collectionKey.replace("projects/", "").replace("/events", "")
    localStorage["cache-time-events-" + code] = new Date()
    return super._getAllPaginated(collectionKey, limit, startAfter)
  }

  async getAllFromCache(code = ""): Promise<Array<EventDTO>> {
    if (!code) throw new Error("'code' should not be empty")
    const offlineData = await super.getOfflineData("")
    if (offlineData) return offlineData
    const key = `getAllFromCache#${code}`
    const cached = this.cacheAll[key]
    if (cached) return Promise.resolve(cached)
    this.setProjectCode(code)
    return super.getAllFromCache().then((data) => {
      this.cacheAll[key] = data
      // localStorage["cache-time-events-" + code] = new Date()
      return data
    })
  }

  _toDTO(raw: any): Promise<EventDTO> {
    const dto = raw.data() as EventDTO
    for (let dtoKey in dto) {
      const value = dto[dtoKey] as any
      if (
        typeof value === "object" &&
        (dtoKey.toLowerCase().includes("date") || dtoKey === "start" || dtoKey === "end") &&
        value?.seconds
      ) {
        // @ts-ignore
        dto[dtoKey] = new Date(value.seconds * 1000) // the type "timestamp" from firestore is not standard
      }
    }
    return Promise.resolve({
      ...dto,
      id: raw.id,
    })
  }
}
