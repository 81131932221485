import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core"
import { LazyLoadEvent } from "primeng/api"

import {
  ActionClickEvent,
  ListQuery,
  ListSortParameters,
  TableData,
  TableHeader,
} from "@shared/component/list/list.model"
import { Table } from "primeng/table"

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent<T> implements OnInit, AfterViewInit {
  static hasFilter = (filters: any) => {
    return (
      Object.values(filters).filter((x: any) => {
        if (Array.isArray(x?.value)) {
          return x.value.length > 0
        }
        return !!x.value
      }).length > 0
    )
  }

  now = Date.now()
  globalFilterFields: Array<string> = []
  allValues: T[] = []
  totalRecords = 0
  currentPage = 1
  rowsPerPageOptions = [15, 20, 50, 100, 200]

  @ViewChild("table", { static: false }) table?: Table
  @Input() expansionRowTemplate: TemplateRef<any> | null = null
  @Input() showExpandAll = true

  @Input()
  get tableData(): TableData<T> {
    return this.data
  }

  set tableData(data: TableData<T>) {
    this.data = data
    this.totalRecords = data.totalRecords
    this.allValues = this.data.values
    this.globalFilterFields = this.data.headers.map((x) => x.field)
  }

  inputValue = ""
  data: TableData<T> = { headers: [], values: [], actions: [], pageNumber: 0, totalRecords: 0 }
  mapFilteringOptions: { [key: string]: Array<any> } = {} as any

  @Input() stateStorage: "session" | "local" = "local"
  @Input() storageKey = ""
  @Input() scrollHeight = `${window.innerHeight - 65}px`
  @Input() infiniteScroll = false
  @Input() selectedRow = ""
  @Output() selectedRowChange = new EventEmitter<string>()
  @Input() loading = true
  @Input() rows = 15
  /** only used for the first fetch */
  @Input() initSorts: ListSortParameters[] | null = null
  @Input() defaultSorts: ListSortParameters[] | null = null
  @Input() dataKey!: string
  @Input() lazy = true
  @Input() expandable = false
  @Input() filtering = false

  @Output() actionClick = new EventEmitter<ActionClickEvent<T>>()
  @Output() fetchData = new EventEmitter<ListQuery>()
  @Output() fetchMoreData = new EventEmitter()
  @Input() getFilteringMap: (header: TableHeader[]) => Promise<{ [key: string]: Array<any> }> = (e) =>
    Promise.resolve({})
  @Input() colorizingRow: (item: any) => boolean = () => false

  constructor() {}

  ngOnInit(): void {
    if (!this.rowsPerPageOptions.includes(this.rows)) {
      this.rowsPerPageOptions.push(this.rows)
      this.rowsPerPageOptions.sort((a, b) => a - b)
    }

    this.getFilteringMap(this.data.headers).then((res) => (this.mapFilteringOptions = res))

    if (!this.storageKey) {
      throw new Error("app-list storageKey should not be undefined")
    }
  }

  ngAfterViewInit() {
    // otherwise, the global filter is keep: side effects :( (Primeng table => [localstorage])
    if (this.table?.filters?.global) (this.table.filters.global as any).value = ""
  }

  onActionClick(actionId: string, item: T, index: number): void {
    this.actionClick.emit({ actionId, item, index })
  }

  loadNextPage(event: LazyLoadEvent): void {
    // console.log(event)

    const firstElement = event?.first ?? 0
    const elementsPerPage = event?.rows ?? 1
    this.currentPage = firstElement / elementsPerPage + 1

    if (event?.filters?.date?.matchMode?.endsWith("Now")) {
      event.filters.date.value = new Date() // hack because when selecting "Dans le futur" or "Dans le passé", if value is null: nothing happen!
    }

    const query: ListQuery = {
      page: {
        pageNumber: this.currentPage,
        elementsPerPage: event.rows || 5,
      },
      search: this.inputValue || "",
    }
    if (event.sortField) {
      query.sort = {
        sortOrder: event.sortOrder === 1,
        fieldToSort: event.sortField,
      }
    }
    if (event.multiSortMeta) {
      query.sorts = event.multiSortMeta.map((v) => ({
        sortOrder: v.order === 1,
        fieldToSort: v.field,
      }))
    }

    if (event.filters) {
      query.filters = event.filters
    }
    this.fetchData.emit(query)
  }

  getStyle(header: TableHeader, value: string, color?: string): string {
    if (header.field === "color") return "color:" + value
    if (color) return "color:" + color
    return ""
  }

  getSortField(header: TableHeader): string {
    return header.sort ? (header.sortField ? header.sortField : header.field) : ""
  }

  public removeItemFromTable(item: T, matchingProperty: string): boolean {
    // @ts-ignore
    const index = this.data.values.findIndex((x) => x[matchingProperty] === item[matchingProperty])

    if (index >= 0) {
      this.data.values.splice(index, 1)
      this.totalRecords--
      return true
    }
    return false
  }

  /*
  private getFirstRowIndex(): number {
    return this.table._first
  }
  public addNewItemToTable(item: T): void {
    this.data.values.splice(this.getFirstRowIndex(), 0, item)
    this.selectedRow = (item as any)?.id || ""
    this.totalRecords++
  }*/

  public showMore(): void {
    if ((this.inputValue && this.inputValue.trim().length > 0) || ListComponent.hasFilter(this.table?.filters)) {
    } else {
      console.log("showMore")
      this.fetchMoreData.emit()
    }
  }

  expandAll(): void {
    this.table?.destroyStyleElement()
    this.table?.saveState()
  }
}
