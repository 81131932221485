import { Injectable } from "@angular/core"
import { EventApi } from "@fullcalendar/core"
import { ProjectService } from "@app/pages/performance/project.service"
import { EventService } from "@app/pages/performance/event.service"
import { ViewService } from "@app/pages/performance/view.service"
import { REFERENTIAL, ReferentialService } from "@app/pages/referential/referential.service"

@Injectable({
  providedIn: "root",
})
export class CalendarService {
  key = "planning"
  events: Array<EventDTO> = []
  mapColors = {}

  constructor(
    private projectService: ProjectService,
    private eventService: EventService,
    private viewService: ViewService,
    private refService: ReferentialService
  ) {
    this.updateColors()
  }

  async duplicateEventsOnly(projectSrc: string, projectDest: string) {
    console.log(`Starting ${projectSrc} to ${projectDest}`)
    const events = await this.getEvents(projectSrc)
    this.setProjectCode(projectDest)
    await Promise.all(events.map(async (evt) => await this.eventService.create(evt, evt.id)))
    console.log(`Done copying ${projectSrc} to ${projectDest}`)
  }

  updateColors() {
    this.refService.getAllFromCache(REFERENTIAL.planning_event_category).then((refs) => {
      refs.forEach((ref) => (this.mapColors[ref.id] = ref.color))
    })
  }

  setProjectCode(code: string): void {
    this.eventService.setProjectCode(code)
    this.viewService.setProjectCode(code)
    this.updateColors()
  }

  deleteEvent(projectCode: string, eventId: string): Promise<any> {
    return this.eventService.delete(eventId)
  }

  createEvent(projectCode: string, event: EventQuery): Promise<EventAndRoot> {
    const rootEvent = {
      ...event,
      rootId: "",
    }
    rootEvent.resourceIds = []
    return this.eventService.create(rootEvent).then((root) => {
      const newEvent: any = {
        title: "",
        type: "",
        backgroundColor: "",
        // start: null,
        // end: null,
        rootId: root.id,
        resourceIds: event.resourceIds,
        location: "",
        location2: "",
        note: "",
      }
      return this.eventService.create(newEvent).then((event) => ({ event: this.getEventView(event, root), root: root }))
    })
  }

  addResourcesToRootEvent(projectCode: string, rootId: string, resources: Array<string>): Promise<Array<EventDTO>> {
    return this.eventService.get(rootId).then((root) => {
      const promises: Array<Promise<EventDTO>> = []
      resources.forEach((res) => {
        const newEvent = {
          type: "",
          title: "",
          backgroundColor: "",
          rootId: rootId,
          resourceIds: [res],
          location: "",
          location2: "",
          note: "",
        }
        promises.push(this.eventService.create(newEvent))
      })
      return Promise.all(promises).then((results) => results.map((x) => this.getEventView(x, root)))
    })
  }

  updateEvent(projectCode: string, eventId: string, query: EventUpdateQuery): Promise<EventDTO> {
    // console.log("updateEvent", eventId)
    return this.eventService.get(eventId).then((eventToUpdate) => {
      const patchEvent = (evt: EventDTO, values: EventUpdateQuery): void => {
        if (values.category !== undefined) {
          eventToUpdate.backgroundColor = "" // deprecated (old data)
          eventToUpdate.category = values.category
        }
        if (values.title !== undefined) {
          eventToUpdate.title = values.title
        }
        eventToUpdate.start = values.start
        if (values.start === undefined) {
          // @ts-ignore
          eventToUpdate.start = "" //firestore.FieldValue.delete()
        }
        eventToUpdate.end = values.end
        if (values.end === undefined) {
          // @ts-ignore
          eventToUpdate.end = "" //firestore.FieldValue.delete()
        }

        if (values.resourceIds !== undefined) {
          eventToUpdate.resourceIds = values.resourceIds
        }
        if (values.location !== undefined) {
          eventToUpdate.location = values.location
        }
        if (values.location2 !== undefined) {
          eventToUpdate.location2 = values.location2
        }
        if (values.note !== undefined) {
          eventToUpdate.note = values.note
        }
        if (values.note !== undefined) {
          eventToUpdate.action = values.action
        }
        if (values.companions !== undefined) {
          eventToUpdate.companions = values.companions
        }
      }
      patchEvent(eventToUpdate, query)
      return this.eventService.update(eventToUpdate)
    })
  }

  getEvents(
    projectCode: string,
    shouldUseCache = false,
    exclusionFields: Array<string> = [] /* enum EVENT_CATEGORY */
  ): Promise<Array<EventDTO>> {
    this.setProjectCode(projectCode)
    // !! Be careful using cache here, when the admin updating events, cache is not updated properly !!
    // !! Cache should be used only when then user cannot update events!!
    return (shouldUseCache ? this.eventService.getAllFromCache(projectCode) : this.eventService.getAll())
      .then((events) => {
        const eventsView: Array<EventDTO> = []
        events.forEach((event) => {
          if (event.rootId) {
            const root = events.find((r) => r.id === event.rootId)
            if (root) {
              eventsView.push(this.getEventView(event, root))
            }
          } else {
            eventsView.push(event)
          }
        })
        return eventsView
      })
      .then((events) => {
        if (exclusionFields.length > 0) {
          return this.refService.getAllFromCache(REFERENTIAL.planning_event_category).then((eventRefCategory) => {
            const excludeCategoriesId = eventRefCategory
              .filter((x) => exclusionFields.filter((field) => x[field]).length > 0)
              .map((x) => x.id)
            return events.filter((evt) => !evt.category?.id || !excludeCategoriesId.includes(evt.category.id))
          })
        }
        return events
      })
  }

  getEventView(event: EventDTO, root: EventDTO): EventDTO {
    return {
      // when modifing here, please update CF for google calendar synchro
      rootId: event.rootId,
      id: event.id,
      type: event.type || root.type,
      title: event.title || root.title,
      start: event.start || root.start,
      end: event.end || root.end,
      resourceIds: event.resourceIds,
      backgroundColor:
        this.mapColors[(event.category || root.category)?.id] || event.backgroundColor || root.backgroundColor || "",
      category: event.category || root.category,
      location: event.location || root.location,
      location2: event.location2 || root.location2,
      note: event.note || root.note,
      action: event.action || root.action,
      companions: root.companions,
    }
  }

  getEventById(projectCode: string, eventId: string): Promise<EventDTO> {
    return this.eventService.get(eventId)
  }

  /*emptyEventDTO(): EventDTO {
    return {
      id: "",
      type: "",
      rootId: "",
      backgroundColor: "",
      title: "",
      resourceIds: [],
      location: "",
      location2: "",
      note: "",
    }
  }*/

  getEventAndRoot(projectCode: string, event: EventApi): Promise<EventAndRoot> {
    //TODO ALBAN => appels firestore évitables??
    return Promise.all([this.eventService.get(event.id), this.eventService.get(event.extendedProps.rootId)]).then(
      (res) => ({
        event: res[0],
        root: res[1],
      })
    )
  }
}

export interface EventDTO {
  id: string
  type: string
  title: string
  location: string
  location2: string
  note: string
  action: string
  start?: Date
  end?: Date
  resourceIds: string[]
  backgroundColor?: string // calculated
  category: any
  rootId: string
  companions: boolean
}

type EventQuery = Omit<EventDTO, "id" | "rootId">

export type EventCreateQuery = {
  start: Date
  end: Date
  resourceIds: string[]
}

export type EventAndRoot = { event: EventDTO; root: EventDTO }

export type EventUpdateQuery = Omit<EventDTO, "id" | "rootId">
