import { Injectable } from "@angular/core"
import { EventApi } from "@fullcalendar/core"

@Injectable({
  providedIn: "root",
})
export class ExportIcsService {
  constructor() {}

  downloadBlob(blob: Blob | File, filename: string): void {
    const objectUrl = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.style.display = "none"
    document.body.appendChild(link)
    link.href = objectUrl
    link.href = URL.createObjectURL(blob)
    link.download = filename
    link.click()
    document.body.removeChild(link)
  }

  convertDateToIcsFormat(date: Date) {
    return `${date.toISOString().split(".")[0].replace(/[-:]/g, "")}Z`
  }

  exportIcsFile(events: EventApi[], name: string) {
    let eventsExport = ""
    const description = "Ceci est une fausse description"
    events.forEach((event, i) => {
      eventsExport += `BEGIN:VEVENT
UID:test-${i}
DTSTART: ${this.convertDateToIcsFormat(event.start || new Date())}
DTEND:${this.convertDateToIcsFormat(event.end || new Date())}
SUMMARY:${event.title}
DESCRIPTION:${description}
END:VEVENT
`
    })

    const content = `BEGIN:VCALENDAR
CALSCALE:GREGORIAN
METHOD:PUBLISH
PRODID:-//Test Cal//EN
VERSION:2.0
${eventsExport}END:VCALENDAR`

    const data = new File([content], { type: "text/plain" } as any)
    this.downloadBlob(data, `Export_${name}.ics`)
  }
}
