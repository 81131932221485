import { NgModule } from "@angular/core"
import { PreloadAllModules, RouterModule, Routes } from "@angular/router"
import { MainLayoutComponent } from "@app/layout/main-layout/main-layout.component"
import { redirectLoggedInTo, redirectUnauthorizedTo } from "@angular/fire/auth-guard"
import { AuthGuard, ROLES } from "@app/auth/auth-guard"
import { ExternPerformancesComponent } from "@app/pages/extern/extern-projects/extern-performances.component"

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"])
const redirectLoggedInToExtern = () => redirectLoggedInTo(["extern"])
const routes: Routes = [
  {
    path: "extern",
    loadChildren: () => import("./pages/extern/extern.module").then((m) => m.ExternModule),
    canActivate: [AuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      roles: [ROLES.PLANNING],
    },
  },
  {
    path: "",
    component: MainLayoutComponent,
    children: [
      {
        path: "planning",
        loadChildren: () => import("./pages/planning/planning.module").then((m) => m.PlanningModule),
      },
      { path: "person", loadChildren: () => import("./pages/person/person.module").then((m) => m.PersonModule) },
      {
        path: "organization",
        loadChildren: () => import("./pages/organization/organization.module").then((m) => m.OrganizationModule),
      },
      {
        path: "performance",
        loadChildren: () => import("./pages/performance/performance.module").then((m) => m.PerformanceModule),
      },
      {
        path: "program",
        loadChildren: () => import("./pages/program/program.module").then((m) => m.ProgramModule),
      },
      {
        path: "unit",
        loadChildren: () => import("./pages/unit/unit.module").then((m) => m.UnitModule),
      },
      {
        path: "referential",
        loadChildren: () => import("./pages/referential/referential.module").then((m) => m.ReferentialModule),
      },
      {
        path: "settings",
        loadChildren: () => import("./pages/settings/settings.module").then((m) => m.SettingsModule),
      },
      {
        path: "seniority",
        loadChildren: () => import("./pages/seniority/seniority.module").then((m) => m.SeniorityModule),
      },
      {
        path: "remuneration",
        loadChildren: () => import("./pages/remuneration/remuneration.module").then((m) => m.RemunerationModule),
      },
      {
        path: "crm",
        loadChildren: () => import("./pages/crm/crm.module").then((m) => m.CrmModule),
      },
      {
        path: "extern-admin",
        component: ExternPerformancesComponent,
      },
      { path: "**", redirectTo: "performance", pathMatch: "prefix" },
    ].map((x: any) => {
      if (x.path !== "**") {
        x.canActivate = [AuthGuard]
        const roles = ["planning", "performance", "extern-admin"].includes(x.path)
          ? [ROLES.ADMIN, ROLES.PLANNING]
          : [ROLES.ADMIN]

        x.data = {
          authGuardPipe: redirectUnauthorizedToLogin,
          // // In mobile we only support the "extern" view
          // authGuardPipe: isMobile()
          //   ? redirectLoggedInToExtern /*extern routing will redirect (if needed) to redirectUnauthorizedToLogin*/
          //   : redirectUnauthorizedToLogin,
          roles,
        }
        // https://github.com/angular/angularfire/blob/master/docs/auth/router-guards.md
      }
      return x
    }),
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules /** ??? ***/ })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
