import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms"
import { Dropdown } from "primeng/dropdown"
import { DateUtils } from "@shared/util/date-utils"

@Component({
  selector: "app-time-picker",
  templateUrl: "./time-picker.component.html",
  styleUrls: ["./time-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
  ],
})
export class TimePickerComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @ViewChild("dropDown") dropDownComponent?: Dropdown

  inputValue = "" // Displayed value
  value = "" // Model value

  @Input() options: { value: string; label: string }[] = []
  @Input() optionValue = "value"
  @Input() optionLabel = "label"
  @Input() required = false
  @Output() changing = new EventEmitter()

  constructor(private injector: Injector) {
    if (this.options.length === 0) {
      this.initHours()
    }
  }

  ngAfterViewInit(): void {
    const control = this.injector.get(NgControl, null)?.control
    const originalMethod = control?.markAsDirty
    if (control) {
      control.markAsDirty = () => {
        // @ts-ignore
        originalMethod.apply(control, arguments)
        // monkey patching for managing 'dirty' properly
        // (angular issue/limitation: https://github.com/vmware/clarity/issues/3191)
        this.dropDownComponent?.el.nativeElement.classList.add("ng-dirty")
      }
    }
  }

  ngOnInit(): void {}

  timeChange(event: any): void {
    const value = (event.value || "").trim()
    if (this.isTimeValid(value)) {
      if (this.value !== value) {
        this.value = value
        this.addNewCustomOption(value)
        this.onChange(value)
        this.changing.emit()
      }
    }
  }

  blur(): void {
    if (!this.isTimeValid(this.inputValue)) {
      if (this.writeValue(this.value)) {
        this.changing.emit()
      }
    }
  }

  isTimeValid(str: string): boolean {
    str = (str || "").trim()
    if (/^[0-9]{1,2}:[0-9]{1,2}$/.test(str)) {
      const [h, m] = str.split(":")
      return +h < 25 && +m < 60 && m.length === 2 && h.length === 2
    }
    return false
  }

  // Function to call when the rating changes.
  onChange = (value: string) => {}
  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {}

  // Allows Angular to update the model
  writeValue(value: string): boolean {
    value = (value || "").trim()
    const changing = value !== this.value
    this.value = value
    this.inputValue = value
    if (changing) {
      this.addNewCustomOption(value)
      this.onChange(this.value)
      return true
    }
    return false
  }

  addNewCustomOption(value: string): void {
    if (value && !this.options.find((x) => x.value === value)) {
      this.options.push({
        value: value,
        label: value,
      })
      this.options.sort((a, b) => (a.value > b.value ? 1 : -1))
    }
  }

  // Allows Angular to register a function to call when the model changes.
  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn
  }

  // Allows Angular to register a function to call when the input has been touched.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    // this.disabled = isDisabled
  }

  private initHours(): void {
    this.options = []
    const startHour = 0
    const endHour = 24
    for (let i = 0; i < (endHour - startHour) * 4; i++) {
      const date = new Date()
      const hours = startHour + Math.floor(i / 4)
      const minutes = 15 * (i % 4)
      date.setHours(hours)
      date.setMinutes(minutes)
      const hour = DateUtils.formatHH_MM(date)
      this.options.push({
        value: hour,
        label: hour,
      })
    }
  }
}
