import { Injectable } from "@angular/core"
import { FireStoreService } from "@shared/service/fire-store.service"
import { NotifService } from "@shared/service/notif.service"
import { arrayRemove, arrayUnion } from "@angular/fire/firestore"
import { PersonDTO, PersonService } from "@app/pages/person/person.service"
import { PerformanceDTO, PerformanceService } from "@app/pages/performance/peformance.service"
import { AuthService } from "@app/auth"
import { REFERENTIAL, ReferentialService } from "@app/pages/referential/referential.service"

@Injectable({
  providedIn: "root",
})
export class ProjectService extends FireStoreService<ProjectDTO> {
  getPerformanceDates = this.functions.httpsCallable("getPerformanceDates")

  constructor(
    private personService: PersonService,
    private performanceService: PerformanceService,
    private notifService2: NotifService,
    private authService: AuthService,
    private refService: ReferentialService
  ) {
    super()
    this.init({
      collectionKey: "projects",
      fullTextFields: [],
    })
  }

  getPersonIds(projectCode: string, fromCache = false): Promise<Array<string>> {
    return this.get(projectCode, fromCache)
      .then((ids) => ids?.persons?.map((x) => x.id) || [])
      .catch((err) => this.handleError(err, null))
  }

  getPersons(projectCode: string, fromCache = false, onlyNames = true): Promise<Array<PersonDTO>> {
    return this.get(projectCode, fromCache)
      .then((refs) => {
        const persons: Promise<PersonDTO>[] = []
        ;(refs?.persons || []).forEach((ref) =>
          persons.push(this.personService.getWithFields(ref.id, fromCache, onlyNames))
        )
        return Promise.all(persons)
      })
      .catch((err) => this.handleError(err, null))
  }

  addPerson(projectCode: string, personId: string): Promise<boolean> {
    return this.firestore
      .collection(this.config.collectionKey)
      .doc(projectCode)
      .set({ persons: arrayUnion(this.firestore.collection("persons" /*FIXME*/).doc(personId).ref) }, { merge: true })
      .then(() => {
        const cache = this.cache[projectCode]
        if (cache) {
          cache.persons = cache.persons || []
          cache.persons.push(this.firestore.collection("persons" /*FIXME*/).doc(personId).ref)
        }
        return true
      })
      .catch((err) => this.handleError(err, false))
  }

  deletePerson(projectCode: string, personId: string): Promise<boolean> {
    return this.performanceService
      .getPerformancesByProject(projectCode)
      .then((perfs) => {
        const warning = perfs
          .filter((perf) => ((perf?.personsSkills && perf?.personsSkills[personId]) || []).length > 0)
          .map((x) => `[${x.name_label}]`)
          .join("   ")
        if (warning.length > 0) {
          this.notifService2.displayWarning(
            "Pour supprimer cette personne, merci de supprimer sa(ses) compétence(s) de(s) répresentation(s) suivante(s): " +
              warning,
            10000
          )
          return false
        }
        return this.firestore
          .collection(this.config.collectionKey)
          .doc(projectCode)
          .update({ persons: arrayRemove(this.firestore.collection("persons" /*FIXME*/).doc(personId).ref) })
          .then(() => {
            const cache = this.cache[projectCode]
            if (cache) {
              const array = cache.persons || []
              const index = array.findIndex((x: any) => x?.id === personId)
              if (index >= 0) {
                array.splice(index, 1)
              }
            }
            return true
          })
      })
      .catch((err) => this.handleError(err, false))
  }

  async get(id: string, fromCache = false, getPerformanceDates = false): Promise<ProjectDTO> {
    const offlineData = await this.getOfflineData("get" + id)
    if (offlineData) return offlineData
    return super.get(id, fromCache).then((result) => {
      result = result || {}
      result.performanceDates = result.performanceDates || []

      if (!getPerformanceDates || (fromCache && result.performanceDates.length > 0)) {
        this.setOfflineData("get" + id, result)
        return result
      }

      return this.getPerformancesDates(id).then((dates) => {
        result.performanceDates = dates
        this.setOfflineData("get" + id, result)
        return result
      })
    })
  }

  async getPerformancesDates(
    projectId: string,
    contractExcluded = false /*!! only working if admin*/
  ): Promise<Array<Date>> {
    const offlineData = await this.getOfflineData("getPerformancesDates_" + projectId)
    if (offlineData) return offlineData
    if (this.authService.isAdmin()) {
      // performances are better this way...
      const refPerfStatus = await this.refService.getService(REFERENTIAL.perf_status).getAllFromCache()
      const projectCodeRef = this.firestore.collection("ref-project-code").doc(projectId).ref
      return this.firestore
        .collection("performances", (ref) => ref.where("code", "==", projectCodeRef))
        .get()
        .toPromise()
        .then((raw) => {
          let perfs: Array<any> = []
          raw?.forEach((r) => perfs.push(r.data()))
          console.log(`!QUOTA! firestore reading - performanceDates ${this.config.collectionKey}`, perfs.length)
          if (contractExcluded) {
            perfs = perfs.filter((p) => {
              const stat = refPerfStatus.find((r) => r.id === p.status?.id) as any
              return !stat?.contractExcluded
            })
          }
          return perfs.map((x) => (x.date?.seconds ? new Date(x.date?.seconds * 1000) : new Date()))
        })
        .then((data) => {
          this.setOfflineData("getPerformancesDates_" + projectId, data)
          return data
        })
        .catch((err) => this.handleError(err, null))
    }

    console.time("getPerformancesDates")
    console.log("calling cloud function 'getPerformancesDates'")
    // only externs !!
    return this.getPerformanceDates({ codeId: projectId })
      .toPromise()
      .then((data) => {
        const dates = ((data as Array<string>) || []).map((x) => new Date(x))
        console.timeEnd("getPerformancesDates")
        this.setOfflineData("getPerformancesDates_" + projectId, dates)
        return dates
      })
      .catch((err) => this.handleError(err, null))
  }

  getProjectsIdsByPerson(personId: string): Promise<Array<string>> {
    return this.firestore
      .collection(this.config.collectionKey, (ref) =>
        ref.where("persons", "array-contains", this.firestore.doc("persons/" + personId).ref)
      )
      .get()
      .toPromise()
      .then((res) => {
        const items: Array<string> = []
        res?.forEach((r) => {
          items.push(r?.id)
        })
        console.log(`!QUOTA! firestore reading - getProjectsByPerson ${this.config.collectionKey}`, items.length)
        return items
      })
      .catch((err) => this.handleError(err, null))
  }

  getPersonDistribution(performance: PerformanceDTO): Promise<Array<PersonDTO>> {
    return this.getPersons(performance.code?.id, true).then((persons) => {
      return this.refService.getAllFromCache(REFERENTIAL.skills).then((allSkills) => {
        const results = persons
          ?.filter((person) => {
            const skills = (performance?.personsSkills && performance?.personsSkills[person?.id]) || []
            return (skills?.filter((s) => allSkills.find((x) => x.id === s)?.distribution) || []).length > 0
          })
          .sort((a, b) => ((a.lastName || "") > (b.lastName || "") ? 1 : -1))
        // returns only persons with a skill where (distribution === true)
        return results
      })
    })
  }
}

export interface ProjectDTO {
  id: string
  performanceDates: Array<Date>
  // code: { id: string }
  persons: Array<{ id: string }>
}
