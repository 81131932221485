import { Injectable } from "@angular/core"
import { FireStoreService } from "@shared/service/fire-store.service"
import { REFERENTIAL, ReferentialDTO, ReferentialService } from "@app/pages/referential/referential.service"

@Injectable({
  providedIn: "root",
})
export class UnitService extends FireStoreService<UnitDTO> {
  constructor(private refService: ReferentialService) {
    super()
    this.init({
      collectionKey: "units",
      fullTextFields: ["name"],
      sorting: {
        field: "name",
      },
    })
  }

  toDTO(dto: any): Promise<UnitDTO> {
    // TO BE IMPROVED
    return Promise.all([this.refService.getAllFromCache(REFERENTIAL.unity_type)]).then((refs) => {
      const [refUnit] = refs
      const ref = refUnit.find((x: ReferentialDTO) => x.id === dto.type?.id)
      dto.type_label = ref?.name || "?"
      dto.type_color = ref?.color || "?"
      return dto
    })
  }

  private _setIdToVersionsAndRoles(cmd: any): any {
    cmd.versions = cmd.versions?.map((version: UnitVersionDTO) => {
      version.id = version.id || this.uuid()
      version?.roles?.map((role: UnitRoleDTO) => {
        role.id = role.id || this.uuid()
        return role
      })
      return version
    })
    return cmd
  }

  beforeSavingHook(dto: any): any {
    return this._setIdToVersionsAndRoles(dto)
  }
}

export interface UnitDTO {
  id: string
  name: string
  type: { id: string /*REFERENTIAL.unity_type*/ }
  versions: Array<UnitVersionDTO>

  type_label?: string
  type_color?: string
  // units: UnitDTO
}

export interface UnitVersionDTO {
  id: string
  duration: string
  name: string
  roles: Array<UnitRoleDTO>
}

export interface UnitRoleDTO {
  id: string
  name: string
  points: number
}
