import { Injectable } from "@angular/core"
import { FireStoreService } from "@shared/service/fire-store.service"
import { UnitDTO, UnitService } from "@app/pages/unit/unit.service"
import { arrayUnion } from "@angular/fire/firestore"

@Injectable({
  providedIn: "root",
})
export class ProgramService extends FireStoreService<ProgramDTO> {
  constructor(private unitService: UnitService) {
    super()
    this.init({
      collectionKey: "programs",
      fullTextFields: ["name"],
      sorting: {
        field: "name",
      },
    })
  }

  beforeSavingHook(dto: any): any {
    if (!dto.versions) return dto // allowing partial update without erasing existing versions...
    return {
      ...dto,
      versions: (dto.versions || []).map((version: any) => ({
        ...version,
        units: (version.units || []).map((unit: any) => ({
          ...unit,
          id: unit.id || this.uuid(),
          value: this.firestore.doc("units/" + unit.value.id).ref,
        })),
      })),
    } // return a clone otherwise there is a blinking
  }

  toDTO(dto: any, action: string): Promise<ProgramDTO> {
    // if (action === FireStoreService.ACTION.GET || action === FireStoreService.ACTION.UPDATE) {
    const promises: Array<Promise<any>> = []
    if (dto.versions) {
      dto.versions.forEach((version: any) =>
        (version?.units || []).forEach((unit: any) => {
          if (unit?.value?.id) {
            promises.push(this.unitService.get(unit.value.id, true).then((value) => (unit.value = value)))
          }
        })
      )
    }

    return this.unitService
      .getAllFromCache()
      .then(() => Promise.all(promises))
      .then(() => dto)
    // } else {
    //   return super.toDTO(dto, action)
    // }
  }

  createVersion(programId: string, version: any): Promise<string> {
    if (version.units)
      version.units = (version.units || []).map((unit: any) => ({
        ...unit,
        id: unit.id || this.uuid(),
        value: this.firestore.doc("units/" + unit.value.id).ref,
      }))

    version.id = this.uuid()
    return this.firestore
      .collection(this.config.collectionKey)
      .doc(programId)
      .set({ versions: arrayUnion(version) }, { merge: true })
      .then(() => version.id)
      .catch((err) => this.handleError(err, ""))
  }

  getNewVersionConfiguration(name: string): ProgramConfigurationDTO {
    return { name, id: this.uuid(), unitsConf: {} }
  }
}

export interface ProgramDTO {
  id: string
  name: string
  name_official: string
  versions: Array<ProgramVersionDTO>
}

export interface ProgramVersionDTO {
  id: string
  name: string
  units: Array<UnitProgramDTO>
  configurations: Array<ProgramConfigurationDTO>
}

export interface ProgramConfigurationDTO {
  id: string
  name: string
  unitsConf: {
    [unitId: string]: string /*unit versionId*/
  }
}

export interface UnitProgramDTO {
  value: UnitDTO /*stored in firestore as a reference!!*/
  id: string
}
