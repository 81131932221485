import { Component, Input, OnInit } from "@angular/core"
import { RowContactDTO } from "@app/pages/person/person.service"
import { GenericDropDownView } from "@shared/component/dropdown-ref-firestore/dropdown-ref-firestore.component"
import { REFERENTIAL, ReferentialDTO, ReferentialService } from "@app/pages/referential/referential.service"

@Component({
  selector: "app-contact-field",
  templateUrl: "./contact-field.component.html",
  styleUrls: ["./contact-field.component.scss"],
})
export class ContactFieldComponent implements OnInit {
  REFERENTIAL = REFERENTIAL
  @Input() type: "string" | "dropdown" | "date" | "gender" = "string"
  @Input() label = ""
  @Input() row!: RowContactDTO<string | Array<{ id: string }> | Date>
  @Input() fetchData: () => Promise<GenericDropDownView[]> = () => Promise.resolve([])
  @Input() multiFieldName = ""
  @Input() uuid = ""
  showVersions = false
  genderOptions = this.refService.getGenderRef()
  referentialLabels: Array<{ name: string; id: string }> = []

  selected: string | Array<{ id: string }> | Date = ""
  skills: ReferentialDTO[] = []

  constructor(public refService: ReferentialService) {
    this.refService.getAllFromCache(REFERENTIAL.skills).then((skills) => (this.skills = skills))
    this.refService
      .getService(REFERENTIAL.contact_label)
      .getAllFromCache()
      .then((items) => (this.referentialLabels = items as any))
  }

  _formatRef(value: any): string {
    const arr = (value || []).map((x: any) => x?.id || "").sort()
    return arr.join("##")
  }

  noChanges(): boolean {
    // not optimal (called a lot of time FIXME: Alban)
    if (!this.row.versioning) return false
    const vers: any = this.row.versioning[0]?.value
    const versLabel: any = this.row.versioning[0]?.label || ""
    if (versLabel !== (this.row.label || "")) return false

    const value: any = this.row.value
    if (this.type === "dropdown") {
      return this._formatRef(this.row.value) === this._formatRef(this.row.versioning[0]?.value)
    }
    if (this.type === "date") {
      return value?.getTime && vers?.getTime && value.getTime() === vers.getTime()
    }
    return this.row.value === this.row.versioning[0]?.value
  }

  ngOnInit(): void {
    this.selected = (this.row?.versioning && this.row.versioning[0]?.value) || ""
  }

  formatSkills(skills: any): string {
    return skills.map((x: any) => this.skills.find((y) => x?.id === y.id)?.name || "").join(", ")
  }
}
